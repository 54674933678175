import {
  Button,
  Flex,
  Icon
} from "@chakra-ui/react";
import { db } from "config/firebase";
import { addDoc, collection } from "firebase/firestore";
import { nanoid } from "nanoid";
import { useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { BsEmojiSmile } from "react-icons/bs";
import { HiMiniPaperClip } from "react-icons/hi2";

  const ChatInput=({selectedChat})=>{
    const [Message,setMessage]=useState();
    const currentDate=new Date();

    const sendMessage=async()=>{
        try{
            const messageCollectionRef=collection(db,`Chats/${selectedChat?.docId}/Messages`);
            if(Message.length > 0 && selectedChat){
                const MessageData={name:selectedChat?.clientName,messageBy:"client",messageType:"text",message:Message,messageId:nanoid(16),exactTime:currentDate.getTime(),createdAt:currentDate}
                console.log("Message Data:",MessageData);
                await addDoc(messageCollectionRef,MessageData);
            }
            setMessage("");
        }catch(error){
            console.log(error);
        }
    }

    const handleMessage=(e)=>{
        let {value}=e.target;
        console.log(value);
        setMessage(value);
    }

    return(
        <Flex
        p="0.5rem 1rem"
        alignItems="center"
        height="3rem"
        bgColor="#E1F2EF"
        borderRadius="1rem"
        justifyContent={'space-around'}
      >
        <Flex marginRight="5px">
            <Icon as={BiAddToQueue} />
            <Icon as={HiMiniPaperClip} ml="1rem" />
            <Icon as={BsEmojiSmile} ml="1rem" />
        </Flex>
        <input type="text" value={Message} placeholder="Enter Message" style={{padding:"5px",width:"60%"}} onChange={handleMessage}/>
        <Button
          height={7}
          borderRadius={5}
          color="#fff"
          bgColor="#1C6ED0BF"
          onClick={sendMessage}
        >
          Send
        </Button>
      </Flex>
    )
  }

export default ChatInput;