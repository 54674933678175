import pic1 from "./assets/General 1.png";
import pic2 from "./assets/Customer-Support-24 1.png";
import pic3 from "./assets/Sales-Support 1.png";
import pic4 from "./assets/Partner-Support 1.png";

const supportData = [
  {
    img: pic1,
    head: "General Queries",
    data: "Whether it's a question or a concern, we're here to provide the support you need!",
    button: "Ask us anything!",
  },
  {
    img: pic2,
    head: "Support",
    data: "Looking for assistance? You're in the right place. Let us know how we can help!",
    button: "Get Support",
  },
  {
    img: pic3,
    head: "Sales Queries",
    data: "Sales inquiries? Consider them sorted with our expert assistance!",
    button: "Contact Sales Team",
  },
  {
    img: pic4,
    head: "Partner Support",
    data: "Partnerships make us stronger! Connect with our support team for partner inquiries.",
    button: "Ask us anything!",
  },
];

export default supportData;
// export const supportPageMobobile = [
//   {
//     img: "Images/supportPage/supportMobile1.png",
//     head: "General Queries",
//     data: "Whether it's a question or a concern, we're here to provide the support you need!",
//     button: "Ask us anything!",
//   },
//   {
//     img: "Images/supportPage/supportMobile2.png",
//     head: "Support",
//     data: "Looking for assistance? You're in the right place. Let us know how we can help!",
//     button: "Get Support",
//   },
//   {
//     img: "Images/supportPage/supportMobile3.png",
//     head: "Sales Queries",
//     data: "Sales inquiries? Consider them sorted with our expert assistance!",
//     button: "Contact Sales Team",
//   },
//   {
//     img: "Images/supportPage/supportMobile4.png",
//     head: "Partner Support",
//     data: "Partnerships make us stronger! Connect with our support team for partner inquiries.",
//     button: "Ask us anything!",
//   },
// ];
