import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/client';
import Login from 'components/Auth/Login';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import Register from 'components/Auth/Signup';
import PrivateRoute from './components/PrivateRoute';
import Meeting from 'components/videocall/Meeting';
import Preloader from 'components/preLoader/PreLoader';

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (you can replace with actual loading logic)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#f8f9fa'
      }}>
        <Preloader type="circle" />
		<Preloader type="triangle" />
		<Preloader type="rectangle" />
      </div>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        {/* <ThemeEditorProvider> */}
          <Router>
            <Switch>
              <PrivateRoute path={`/auth`} component={AuthLayout} />
              <PrivateRoute path={`/client`} component={AdminLayout} />
              <PrivateRoute path={`/rtl`} component={RtlLayout} />
              <PrivateRoute path={`/meeting/:meetingId`} component={Meeting}/>
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Register} />
              <Redirect from='/' to='/client' />
            </Switch>
          </Router>
        {/* </ThemeEditorProvider> */}
      </React.StrictMode>
    </ChakraProvider>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById('root'));


// import React, {createContext} from 'react';
// import ReactDOM from 'react-dom';
// import 'assets/css/App.css';
// import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';
// import AuthLayout from 'layouts/auth';
// import AdminLayout from 'layouts/client';
// import Login from 'components/Auth/Login';
// import RtlLayout from 'layouts/rtl';
// import { ChakraProvider } from '@chakra-ui/react';
// import theme from 'theme/theme';
// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
// import Register from 'components/Auth/Signup';
// import PrivateRoute from './components/PrivateRoute'; 
// import Meeting from 'components/videocall/Meeting';


// ReactDOM.render(
// 	<ChakraProvider theme={theme}>
// 		<React.StrictMode>
			
// 					<Router>
// 					<Switch>
// 						<PrivateRoute path={`/auth`} component={AuthLayout} />
// 						<PrivateRoute path={`/client`} component={AdminLayout} />
// 						<PrivateRoute path={`/rtl`} component={RtlLayout} />
// 						<PrivateRoute path={`/meeting/:meetingId`} component={Meeting}/>
// 						<Route path="/login" component={Login} />
// 						<Route path="/signup" component={Register} />
// 						<Redirect from='/' to='/client' />
// 					</Switch>
// 					</Router>
				
// 		</React.StrictMode>
// 	</ChakraProvider>,
// 	document.getElementById('root')
// );
