import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import React from 'react';
import { Text, useBreakpointValue } from '@chakra-ui/react';

const CustomCard = ({ card }) => {
  
  const cardWidth = useBreakpointValue({ base: '45%', lg: '30%' }); 

  return (
    <Card
    width={{base:"47%",md:"30%"}} height={{base: "unset", md: "130px"}} borderRadius={{base: "18px", md: "25px"}} bgColor={card?.bgColor} shadow="lg" style={{ marginBottom:"2rem"}}
    >
      <Text color="#1B2559" fontWeight="bold" fontSize={{base: ".87rem", md: "1.25rem"}} >{card?.project}</Text>
      <Text color="#1B2559" fontWeight="bold" fontSize={{base: ".87rem", md: "1.25rem"}}>{card?.number}</Text>
      <HSeparator />
    </Card>
  );
};

export default CustomCard;
