import React, { useEffect, useState } from "react";
import CustomCard from "../dashboards/components/CustomCard";
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  Text,
  Select,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Cards,
  ChatData,
  DashboardData,
  EventData,
  EventDataforclient,
} from "./data/DashboardData";
import EventClient from "../../../components/EventClient/EventClient";
import { HSeparator } from "components/separator/Separator";
import MiniCalendar from "components/calendar/MiniCalendar";
import { MdCheckCircleOutline } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ProfilePic from "./assets/Ellipse 658.png";
import ServiceCard from "./components/ServiceCard";
import Resource from "./components/Resource";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { db } from "config/firebase";
import { auth } from "config/firebase";
import { useLocation } from "react-router-dom";
import noMessage from "../assets/No_Messages.png";

const Dashboard = () => {
  const [cards, setCards] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [eventDataClient, setEventDataClient] = useState([]);
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  const [userId, setUserId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [todayMeetings, setTodayMeetings] = useState([]);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [activeTab, setActiveTab] = useState("Inbox");
  const getCookie = (name) => {
    //Add semicolon at starting of cookie string
    let values = `; ${document.cookie}`;
    //Split into array when '; authToken=' part appears.
    let parts = values.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    return null;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
        const userProfileRef = collection(db, "userProfiles");
        const clientEnquiryData = collection(db, "clientFormData");

        const q1 = query(userProfileRef, where("uid", "==", userid));
        const querySnapshot = await getDocs(q1);
        if (!querySnapshot.empty) {
          const UserData = querySnapshot.docs[0].data();
          const email = UserData.personal.email;
          try {
            const q2 = query(
              clientEnquiryData,
              where("personalDetails.email", "==", email)
            );
            const enquirySnapshot = await getDocs(q2);
            const enquiries = [];
            for (let item of enquirySnapshot.docs) {
              enquiries.push(item.data());
            }
            setEnquiryData(enquiries);
          } catch (error) {
            console.log(error.message);
          }
        }
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userId) return;

    // Query Firestore for meetings where userId is the client
    const fetchMeetings = async () => {
      try {
        const meetingsRef = collection(db, "videocallscheduling");
        const q = query(meetingsRef, where("client", "==", userId));
        const querySnapshot = await getDocs(q);

        const meetingsList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMeetings(meetingsList);
        console.log("User ID:", userId);
        console.log("All Meetings:", meetingsList);

        // Separate today's and upcoming meetings
        const todayMeetingsList = [];
        const upcomingMeetingsList = [];

        const now = new Date(); // Current date and time
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset today's date to midnight

        meetingsList.forEach((meeting) => {
          if (!meeting.meetingDate || typeof meeting.meetingDate !== "string") {
            console.error("Invalid or missing date:", meeting.meetingDate);
            return; // Skip invalid dates
          }

          // Convert meeting date string to Date object
          let meetingDate = new Date(meeting.meetingDate);

          if (isNaN(meetingDate.getTime())) {
            console.error("Invalid date format:", meeting.meetingDate);
            return; // Skip invalid dates
          }

          // Set meetingDate to the correct time
          if (meeting.meetingTime) {
            const [hours, minutes] = meeting.meetingTime.split(":").map(Number);
            meetingDate.setHours(hours, minutes, 0, 0);
          } else {
            console.warn("Meeting time missing for:", meeting);
            meetingDate.setHours(23, 59, 59, 999); // Set default time to end of day
          }

          console.log(
            `Meeting Date & Time: ${meetingDate.toLocaleString()} | Now: ${now.toLocaleString()}`
          );

          if (
            meetingDate.getFullYear() === today.getFullYear() &&
            meetingDate.getMonth() === today.getMonth() &&
            meetingDate.getDate() === today.getDate()
          ) {
            // If the meeting time has not yet passed today, add to today's list
            if (meetingDate >= now) {
              todayMeetingsList.push(meeting);
            }
          } else if (meetingDate > now) {
            upcomingMeetingsList.push(meeting);
          }
        });

        console.log("Today's Meetings:", todayMeetingsList);
        console.log("Upcoming Meetings:", upcomingMeetingsList);
        setTodayMeetings(todayMeetingsList);
        setUpcomingMeetings(upcomingMeetingsList);
      } catch (error) {
        console.error("Error fetching meetings:", error);
      }
    };


    fetchMeetings();
  }, [userId]);


  useEffect(() => {
    console.log("Updated Meetings:", meetings);
  }, [meetings]);

  useEffect(() => {
    let cookie = getCookie('authToken');
    setCards(Cards);
    // setChatData(ChatData);
    setDashboardData(DashboardData);
    setEventData(EventData);
    setEventDataClient(EventDataforclient);
  }, []);
  const isMobile = useBreakpointValue({ base: true, md: false });
  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Flex maxW="100%" justifyContent="space-between">
        <Text fontWeight="bold" fontSize={isMobile ? "20px" : "25px"}>
          Welcome back, {displayName}
        </Text>
        <Flex
          width="10rem"
          height="5vh"
          alignItems="center"
          justifyContent="center"
          bgColor="#D7E9FD"
          marginBottom="1rem"
          borderRadius="1rem"
        >
          <Select
            placeholder="Select option"
            colorScheme="#1C6ED059"
            border="none"
            borderRadius={{base: "14px", md: "25px"}}
            fontSize={{base: "13px", md: "16px"}}
            cursor="pointer"
            iconColor="#FFFFFF"
            icon={
              <Icon
                as={ChevronDownIcon}
                backgroundColor="#1C6ED0"
                height="55%"
                borderRadius="2rem"
              />
            }
            style={{
              backgroundColor: "#D7E9FD",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <option value="7days">Last 7 days</option>
            <option value="1month">Last month</option>
            <option value="1year">Last year</option>
          </Select>
        </Flex>
      </Flex>
      <Flex mt="2rem" flexWrap="wrap" justifyContent="space-between">
        {cards.map((item, index) => (
          <CustomCard key={index} card={item} />
        ))}
      </Flex>
      <Flex
        mt="3rem"
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
      >
        <Box
          backgroundColor="#FFFFFF"
          width={isMobile ? "100%" : "55%"}
          height="100%"
          borderRadius="0.6rem"
          mb={isMobile ? "1rem" : "0"}
        >
          <Tabs height="100%">
            <TabList
              pl="1rem"
              pr="1rem"
              fontWeight="bold"
              justifyContent="space-between"
              alignItems="center"
              height="4rem"
              backgroundColor="#E1F2EF"
              borderRadius="0.6rem 0.6rem 0 0"
              display="flex"
              width="100%"
            >
              <Box display="flex" gap=".5rem">
                {["Inbox", "Queries"].map((tab) => (
                  <Tab
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    fontSize={{ base: "12px", md: "19px" }}
                    fontWeight="bold"
                    position="relative"

                    opacity={activeTab === tab ? "1" : activeTab === "View All" ? "0.5" : "0.3"}

                  >
                    {tab}
                  </Tab>
                ))}
              </Box>

              <Spacer />

              <Tab
                fontSize={{ base: "12px", md: "19px" }}
                fontWeight="bold"
                color="#5388FF"
                onClick={() => setActiveTab("View All")}
              >
                View All
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {chatData.length === 0 ? (
                  <Flex
                    justifyContent="center"
                    marginY={"1rem"}
                    color="#263238"
                  >
                    <Box textAlign="center">
                      <Image
                        src={noMessage}
                        height={{ sm: "180px", md: "350px" }}
                        width={{ sm: "250px", md: "350px" }}
                        opacity="0.8"
                      />
                      <Text
                        fontWeight="semibold"
                        mt="1rem"
                        fontSize={{ sm: "1rem", md: "1.3rem" }}
                      >
                        No Message Here
                      </Text>
                    </Box>
                  </Flex>
                ) : (
                  chatData.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Flex p="1.5rem">
                          <Avatar src={ProfilePic} alt="Profile Pic" />
                          <Flex ml="1rem" flexDirection="column">
                            <Text fontWeight="bold">{item?.clientName}</Text>
                            <Text fontSize="sm">{item?.message}</Text>
                          </Flex>
                        </Flex>
                        <HSeparator />
                      </Box>
                    );
                  })
                )}
              </TabPanel>
              <TabPanel>
                {DashboardData.length == 0 ? (
                  <Flex
                    justifyContent="center"
                    alignItems={"center"}
                    flexDirection={"column"}
                    marginY={{ sm: ".2rem", md: "1rem" }}
                    color="#263238"
                  >
                    <Box textAlign="center">
                      <Image
                        src={noMessage}
                        height={{ sm: "250px", md: "350px" }}
                        width={{ sm: "250px", md: "350px" }}
                        opacity="0.9"
                      />
                      <Text
                        fontWeight="semibold"
                        fontSize={{ sm: "1rem", md: "1.3rem" }}
                      >
                        No Message Here
                      </Text>
                    </Box>
                  </Flex>
                ) : (
                  DashboardData.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Flex p="1.5rem">
                          <Avatar src={ProfilePic} alt="Profile Pic" />
                          <Flex ml="1rem" flexDirection="column">
                            <Text fontWeight="bold">{item?.clientName}</Text>
                            <Text fontSize="sm">{item?.message}</Text>
                          </Flex>
                        </Flex>
                        <HSeparator />
                      </Box>
                    );
                  })
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        {/* events */}
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          backgroundColor="#FFFFFF"
          //backgroundColor="#F0F0F0"
          width={isMobile ? "100%" : "40%"}
          height="max-content"
          borderRadius="0.6rem"
          position="relative"
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            position="aboslute"
            backgroundColor="#E1F2EF"
            w="100%"
            h="4rem"
            borderRadius="0.6rem 0.6rem 0 0"
            fontSize="1.5rem"
            fontWeight="bold"
          >
            Upcoming Events
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="center"
            w="100%"
            mt={isMobile ? "4rem" : "0"}
          >
            <MiniCalendar />
          </Flex>
          <Tabs height="100%" w="100%">
            <TabList
              pl="1rem"
              pr="1rem"
              fontWeight="bold"
              justifyContent="space-between"
              alignItems="center"
              height="4rem"
              border="none"
            >
              <Tab fontWeight="bold" fontSize={isMobile ? "10px" : "12px"}>
                Todays Event (2)
              </Tab>
              <Tab fontWeight="bold" fontSize={isMobile ? "10px" : "12px"}>
                Upcoming Event (0)
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {todayMeetings.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      backgroundColor="#F7FCFB"
                      borderRadius="20px"
                      marginBottom="10px"
                      border="none"
                    >
                      {/* <Flex p="1rem" boxShadow="-3px 0px 1px 0px #407BFF" borderRadius="20px" >
                        <Avatar src={ProfilePic} alt="Profile Pic" />
                        <Flex ml="1rem" flexDirection="column">
                          <Text fontWeight="bold">{item?.clientName}</Text>
                          <Text fontSize="sm">{item?.message}</Text>
                         
                        </Flex>
                        <Flex alignItems="center">
                        <Text fontSize="sm">{item?.time}</Text>
                        </Flex>
                      </Flex> */}
                      <EventClient data={item} />
                    </Box>
                  );
                })}
              </TabPanel>
              <TabPanel>
                {upcomingMeetings.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      backgroundColor="#F7FCFB"
                      borderRadius="20px"
                      marginBottom="10px"
                      border="none"
                    >
                      {/* <Flex p="1rem" boxShadow="-3px 0px 1px 0px #407BFF" borderRadius="20px" >
                        <Avatar src={ProfilePic} alt="Profile Pic" />
                        <Flex ml="1rem" flexDirection="column">
                          <Text fontWeight="bold">{item?.clientName}</Text>
                          <Text fontSize="sm">{item?.message}</Text>

                        </Flex>
                        <Flex alignItems="center">
                          <Text fontSize="sm">{item?.time}</Text>
                        </Flex>
                      </Flex> */}
                      <EventClient data={item} />
                    </Box>
                  );
                })}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
      <Flex
        mt="2rem"
        flexWrap="wrap"
        justifyContent="center"
        overflow={isMobile ? "scroll" : ""}
      >
        <ServiceCard />
      </Flex>
      <Box mt="2rem" flexWrap="wrap" justifyContent="space-between">
        <Resource />
      </Box>
    </div>
  );
};

export default Dashboard;
