import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  Button,
  VStack,
  Divider,
  Box,
  Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { auth, db } from "config/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import maleProfilePic from "../../views/admin/clintProfile/assets/maleProfileImage.png";
import femaleProfilePic from "../../views/admin/clintProfile/assets/femaleProfileImage.png";
import userIcon from "../../views/admin/clintProfile/assets/4957136.jpg";
import React from "react";
import { FaRegBell } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import menu from "../../assets/img/dashboards/menu_header.png";
import menu2x from "../../assets/img/dashboards/header_menu2x.png";

const Header = ({ brandText, onOpen }) => {
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [profilePicture, setProfilePicture] = useState("");
  const [UserId, setUserId] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async () => {
    try {
      if (UserId) {
        const ProfileDocRef = collection(db, "userProfiles");
        const q = query(ProfileDocRef, where("uid", "==", UserId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          if (data.profileImage) {
            setProfilePicture(data.profileImage);
          } else {
            console.log("genderInfo:", data.personal.gender);
            if (data.personal.gender === "Male") {
              setProfilePicture(maleProfilePic);
            } else if (data.personal.gender === "Female") {
              setProfilePicture(femaleProfilePic);
            } else {
              setProfilePicture(userIcon);
            }
          }
        } else {
          console.log("User does not exist!");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [UserId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    // <Flex
    //   // alignItems="center"
    //   // justifyContent="space-between"
    //   // p="4"
    //   // bg={{ base: "white", md: "transparent" }}
    //   // boxShadow={{ base: "md", md: "none" }}

    // >
    <>
      {" "}
      {/* Mobile View: Only Hamburger Icon and Avatar */}
      <Flex
        display={{ base: "flex", xl: "none" }}
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        top="0"
        left="0"
        p={{base: 0, md: 4}} boxShadow={{ base: "0px 2px 0px rgba(0, 0, 0, 0.1)", md: "none" }} px={{base: 1, md: 4}} h={{base: "50px", md: "100%"}}
      >
        {/* <HamburgerIcon fontSize="xl" onClick={onOpen} /> */}
        <Flex alignItems={"center"}>
        <Image mt={".5rem"} src={menu2x} onClick={onOpen} h={"10"} />
        <Text fontWeight="bold" ml={{base: 1.1, md: 2}}  fontSize={{ base: "lg", md: "xl" }}>
      {brandText}
    </Text>
    </Flex>
        <Flex alignItems={"center"}>
          <Flex>
        <Icon mr=".7rem" as={MdOutlineEmail} opacity={{base: "0.8", md: "1"}} boxSize={{ base: "1.29rem", md: "1rem" }} />
        <Icon mr=".5rem" as={FaRegBell} opacity={{base: "0.8", md: "1"}} boxSize={{ base: "1.2rem", md: "1rem" }} />
        </Flex>
        <div
          style={{
            borderRadius: "100%",
            height: "40px",
            width: "40px",
            overflow: "hidden",
          }}
        >
          <img src={profilePicture} boxSize="1.5rem" style={{ height: "100%", width: "100%" }} />
        </div>
        </Flex>
      </Flex>
      {/* Desktop View: Full Header Layout without Hamburger Icon */}
      <Flex
        display={{ base: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        w="100%"
      >
        <Text fontWeight="bold" color="#1b2559">
          {brandText}
        </Text>
        <Flex alignItems="center">
          <SearchBar mr="1rem" />
          <Icon mr="1rem" as={MdOutlineEmail} />
          <Icon mr="1.5rem" as={FaRegBell} />
          <div
            style={{
              borderRadius: "100%",
              height: "40px",
              width: "40px",
              overflow: "hidden",
            }}
          >
            <img
              src={profilePicture}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <Flex flexDirection="column">
            <Text mr="1rem" fontWeight="bold">
              {displayName}
            </Text>
            <Text fontSize="sm">CRB</Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
