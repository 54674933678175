import React, { useEffect, useState, useRef } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

const VideoCall = ({ appId, channelName, token, uid, hidden }) => {
  const [client, setClient] = useState(null);
  const [localTracks, setLocalTracks] = useState({ audio: null, video: null });
  const [remoteUsers, setRemoteUsers] = useState({});
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isJoined, setIsJoined] = useState(true);

  const localVideoRef = useRef(null);
  const remoteVideoRefs = useRef({});

  useEffect(() => {
    if (!appId || !channelName || !token || !uid) {
      console.error("Missing required Agora parameters");
      return;
    }

    const agoraClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    setClient(agoraClient);

    const initAgora = async () => {
      try {
        await agoraClient.join(appId, channelName, token, uid);
        setIsJoined(true);

        if (uid !== "999999") {
          const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
          const cameraTrack = await AgoraRTC.createCameraVideoTrack();
          setLocalTracks({ audio: microphoneTrack, video: cameraTrack });

          await agoraClient.publish([microphoneTrack, cameraTrack]);

          if (localVideoRef.current) {
            cameraTrack.play(localVideoRef.current);
          }
        }

        agoraClient.on("user-published", async (user, mediaType) => {
          await agoraClient.subscribe(user, mediaType);
          setRemoteUsers((prevUsers) => ({ ...prevUsers, [user.uid]: user }));

          if (mediaType === "video") {
            setTimeout(() => {
              if (remoteVideoRefs.current[user.uid]) {
                user.videoTrack.play(remoteVideoRefs.current[user.uid]);
              }
            }, 500);
          }
        });

        agoraClient.on("user-unpublished", (user) => {
          setRemoteUsers((prevUsers) => {
            const newUsers = { ...prevUsers };
            delete newUsers[user.uid];
            return newUsers;
          });
        });
      } catch (error) {
        console.error("Agora initialization error:", error);
      }
    };

    initAgora();

    return () => {
      leaveCall();
    };
  }, [appId, channelName, token, uid]);

  const toggleAudio = async () => {
    if (localTracks.audio) {
      await localTracks.audio.setEnabled(!isAudioEnabled);
      setIsAudioEnabled(!isAudioEnabled);
    }
  };

  const toggleVideo = async () => {
    if (localTracks.video) {
      await localTracks.video.setEnabled(!isVideoEnabled);
      setIsVideoEnabled(!isVideoEnabled);
    }
  };

  const leaveCall = async () => {
    if (client) {
      await client.leave();
    }
    if (localTracks.audio) localTracks.audio.close();
    if (localTracks.video) localTracks.video.close();
    setLocalTracks({ audio: null, video: null });
    setIsJoined(false);
  };

  if (hidden) return null;

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px", background: "#f0f0f0", color: "black", width: "100vw", height: "100vh", overflow: "hidden" }}>
      <h2 style={{ marginBottom: "15px", fontSize: "24px", fontWeight: "bold" }}>Agora Video Call</h2>

      {isJoined && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "nowrap", gap: "15px", padding: "10px", borderRadius: "10px", background: "#ffffff", width: "100%", height: "80vh" }}>
          <div style={{ position: "relative", width: "30%", height: "100%", background: "black", borderRadius: "10px", overflow: "hidden", border: "2px solid black" }}>
            <div ref={localVideoRef} style={{ width: "100%", height: "100%" }}></div>
            <p style={{ position: "absolute", bottom: "5px", left: "10px", background: "rgba(0,0,0,0.6)", padding: "5px 10px", borderRadius: "5px", fontSize: "14px", color: "white" }}>You</p>
          </div>

          {Object.values(remoteUsers).map((user) => (
            <div key={user.uid} style={{ position: "relative", width: "30%", height: "100%", background: "gray", borderRadius: "10px", overflow: "hidden", border: "2px solid black" }}>
              <div ref={(el) => (remoteVideoRefs.current[user.uid] = el)} style={{ width: "100%", height: "100%" }}></div>
              <p style={{ position: "absolute", bottom: "5px", left: "10px", background: "rgba(0,0,0,0.6)", padding: "5px 10px", borderRadius: "5px", fontSize: "14px", color: "white" }}>User {user.uid}</p>
            </div>
          ))}

          <div style={{ position: "relative", width: "30%", height: "100%", background: "darkgray", borderRadius: "10px", overflow: "hidden", border: "2px solid black", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <p style={{ fontSize: "20px", fontWeight: "bold", color: "black" }}>BizOwl</p>
          </div>
        </div>
      )}

      {isJoined && (
        <div style={{ marginTop: "20px", display: "flex", gap: "15px" }}>
          <button onClick={toggleAudio} style={{ padding: "12px 20px", background: isAudioEnabled ? "#4CAF50" : "#D32F2F", color: "white", borderRadius: "5px", fontWeight: "bold", cursor: "pointer", transition: "0.3s" }}>
            {isAudioEnabled ? "Mute 🔊" : "Unmute 🔇"}
          </button>
          <button onClick={toggleVideo} style={{ padding: "12px 20px", background: isVideoEnabled ? "#4CAF50" : "#D32F2F", color: "white", borderRadius: "5px", fontWeight: "bold", cursor: "pointer", transition: "0.3s" }}>
            {isVideoEnabled ? "Turn Off Video 🎥" : "Turn On Video 📷"}
          </button>
          <button onClick={leaveCall} style={{ padding: "12px 20px", background: "#D32F2F", color: "white", borderRadius: "5px", fontWeight: "bold", cursor: "pointer", transition: "0.3s" }}>
            Leave Call ❌
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoCall;
