"use client"

import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, useToast } from "@chakra-ui/react"
import { db } from "config/firebase"
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore"

import { onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"
import { States } from "views/admin/clintProfile/data/ServiceData"
import { Gender } from "views/admin/clintProfile/data/ServiceData"
import * as yup from "yup"
import { Fit } from "../data/ServiceData"
import { useMediaQuery } from "@mui/material"
import { auth } from "config/firebase"

const Personal = ({ onUpdate }) => {
  const [states, setStates] = useState(States)
  const [fit, setFit] = useState(Fit)
  const [genderInfo, setGenderInfo] = useState(Gender)
  const [userDetails, setUserDetails] = useState({})
  const toast = useToast()
  const isMobile = useMediaQuery("(max-width:640px)")
  const [UserId, setUserId] = useState()
  const [errors, setErrors] = useState({
    personal: {
      fullName: "",
      phone: "",
      whatsappNo: "",
      city: "",
      state: "",
      email: "",
      fitOption: "",
      gender: "",
    },
  })

  const dataSchema = yup.object().shape({
    personal: yup.object().shape({
      fullName: yup.string().required("This Field is Required!").min(3, "minimum 3 letters").max(20, "max 20 letters"),
      phone: yup.string().required("This Field is Required!"),
      whatsappNo: yup.string().required("Whatsapp Number Required!"),
      city: yup.string().required("This Field is Required!").min(3, "minimum 3 letters").max(40, "max 20 letters"),
      state: yup.string().required("This Field is Required!").min(3, "minimum 3 letters").max(40, "max 20 letters"),
      email: yup.string().required("This Field is Required!").email("Must be a valid email"),
      fitOption: yup.string().required("This Field is Required!"),
      gender: yup.string().required("This Field is Required!"),
    }),
  })
  const [formData, setFormData] = useState({
    personal: {
      fullName: "",
      phone: "",
      whatsappNo: "",
      city: "",
      state: "",
      email: "",
      fitOption: "",
      gender: "",
    },
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid
        setUserId(userid)
      } else {
        console.log("user does not exist in personal section")
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    getData()
  }, [UserId])

  const updateDetails = async () => {
    try {
      const profileDocRef = collection(db, "userProfiles")
      const q = query(profileDocRef, where("uid", "==", UserId))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id
        const docRef = doc(db, "userProfiles", docId)
        await updateDoc(docRef, formData)
        // toast({
        //   title: "Data Updated successfully!",
        //   description: "The user data was succesfully updated!",
        //   status: "success",
        //   duration: "2500",
        //   isClosable: true,
        // })
        toast({
          title: "Profile Updated!",
          description: "Your profile details have been updated and saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top", 
          variant: "solid",
          backgroundColor: "white",
          containerStyle: {
            backgroundColor: "white", 
            border: "1px solid #38A169", 
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "bold",
            padding: "3px",
            boxShadow: "lg",
            color: "#2D3748", 
            boxShadow: "0px 4px 15px rgba(56, 161, 105, 0.2)",
          },
        })
        
        

        // Call the onUpdate callback to recalculate profile completion
        if (onUpdate) onUpdate()
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const getData = async () => {
    try {
      const profileDocRef = collection(db, "userProfiles")
      const q = query(profileDocRef, where("uid", "==", UserId))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs[0].data()
        const personalData = data.personal
        setFormData((prevData) => ({
          ...prevData,
          personal: {
            ...personalData,
          },
        }))
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      personal: { ...prevData.personal, [name]: value },
    }))

    //Validating Specific field
    dataSchema
      .validateAt(`personal.${name}`, { ...formData, personal: { ...formData.personal, [name]: value } })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, personal: { ...prevErrors.personal, [name]: "" } }))
      })
      .catch((err) => {
        setErrors((prevErrors) => ({ ...prevErrors, personal: { ...prevErrors.personal, [name]: err.message } }))
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isFormValid = await dataSchema.isValid(formData)
    if (isFormValid) {
      updateDetails()
    } else {
      toast({
        title: "Form Submission Failed!",
        description: "There are errors in form.",
        status: "error",
        duration: "2500",
        isClosable: true,
      })
    }
  }

  // Update profile completion on field change
  useEffect(() => {
    // Debounce to avoid too many updates
    const timer = setTimeout(() => {
      if (onUpdate) onUpdate()
    }, 500)

    return () => clearTimeout(timer)
  }, [formData])

  return (
    <Box w="100%" borderWidth="1px" borderRadius="lg" overflow="hidden" padding="2rem">
      <form onSubmit={handleSubmit}>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.fullName}>
            <FormLabel htmlFor="fullName">Full Name</FormLabel>
            <Input
              id="fullName"
              name="fullName"
              required
              type="text"
              onChange={handleChange}
              value={formData.personal.fullName}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.personal.fullName}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.phone}>
            <FormLabel htmlFor="phone">Contact Number</FormLabel>
              <Input
                id="phone"
                name="phone"
                type="text" // Use "text" instead of "number" to allow length restrictions
                maxLength={10} // Restricts input to 10 characters
                pattern="\d{10}" // Ensures exactly 10 digits
                onChange={handleChange}
                value={formData.personal.phone}
                style={{ backgroundColor: "white" }}
                
              />

            
            
            <FormErrorMessage>{errors.personal.phone}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.email}>
            <FormLabel htmlFor="email">email</FormLabel>
            <Input
              id="email"
              name="email"
              type="email"
              required
              onChange={handleChange}
              value={formData.personal.email}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.personal.email}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.whatsappNo}>
            <FormLabel htmlFor="whatsappNo">Whatsapp Number</FormLabel>
            <Input
              id="whatsappNo"
              name="whatsappNo"
              type="text" // 
              maxLength={10} 
              pattern="\d{10}" 
              required
              onChange={handleChange}
              value={formData.personal.whatsappNo}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.personal.whatsappNo}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.city}>
            <FormLabel htmlFor="city">City</FormLabel>
            <Input
              id="city"
              name="city"
              type="text"
              onChange={handleChange}
              value={formData.personal.city}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.personal.city}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.state}>
            <FormLabel htmlFor="state">State</FormLabel>
            <Select
              mb="4"
              id="state"
              name="state"
              onChange={handleChange}
              value={formData.personal.state}
              backgroundColor="white"
            >
              {states?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.personal.state}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.fitOption}>
            <FormLabel htmlFor="fitOption">What fits your role?</FormLabel>
            <Select
              mb="4"
              id="fitOption"
              name="fitOption"
              onChange={handleChange}
              value={formData.personal.fitOption}
              backgroundColor="white"
            >
              {fit?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.personal.fitOption}</FormErrorMessage>
          </FormControl>

          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.personal.gender}>
            <FormLabel htmlFor="gender">Sex</FormLabel>
            <Select
              mb="4"
              id="gender"
              name="gender"
              onChange={handleChange}
              value={formData.personal.gender}
              backgroundColor="white"
            >
              {genderInfo?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.personal.gender}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Button colorScheme="blue" type="submit">
          Save
        </Button>
      </form>
    </Box>
  )
}

export default Personal

