import React from "react";
import { Box } from "@chakra-ui/react";
import CoverSection from "./CoverSection";
import SupportSection from "./SupportSection";

const SupportPageBody = () => {
    return (
        <Box maxW="1151px" bg="white" textAlign={'center'} mx="auto" mt={{ base: 24, md: 6 }} pb={4} mb={6} borderRadius="3xl">
            <Box bg="#D7E9FD" borderTopRadius="3xl" h={{ md: "407px" }}>
                <CoverSection />
            </Box>
            <SupportSection />
        </Box>
    );
};

export default SupportPageBody;
