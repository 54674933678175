import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import AvatarImg from "views/admin/project/assets/img/avatar1.png";
import { BsClock, BsThreeDotsVertical } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { GoBell } from "react-icons/go";
import { TbSettingsCog, TbTag } from "react-icons/tb";
import { MdOutlinePriceChange } from "react-icons/md";
import { LiaPercentageSolid } from "react-icons/lia";
import { VscBellSlash } from "react-icons/vsc";
import { FaSackDollar } from "react-icons/fa6";
import Star from "views/admin/project/assets/img/Star.png";
import WebProjectDetails from "views/admin/servicesDetail/projectDetail/WebProjectDetails";
import LogoProjectDetails from "views/admin/servicesDetail/projectDetail/LogoProjectDetails";

const ProjectCard = ({ isRating, props }) => {
  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(0); // State to track the rating
  const textWidth = useBreakpointValue({ base: "50%", md: "auto" });
  const daysLeftWidth = useBreakpointValue({ base: "40%", md: "auto" });

  // Unique key for localStorage based on the project ID or category
  const storageKey = `rating_${props?.category}`;

  // Load the rating from localStorage when the component mounts
  useEffect(() => {
    const savedRating = localStorage.getItem(storageKey);
    if (savedRating) {
      setRating(parseInt(savedRating, 10));
    }
  }, [storageKey]);

  // Function to handle star clicks
  const handleStarClick = (index) => {
    const newRating = index + 1;
    setRating(newRating);
    localStorage.setItem(storageKey, newRating.toString()); // Save the rating to localStorage
  };
  return (
    <>
      <Box
        p=".8rem"
        letterSpacing={"wide"}
        fontSize={{ sm: ".6rem", md: "1rem" }}
        color={"#455A64"}
        paddingBottom={".5rem"}
        paddingTop={".5rem"}
        width={{ sm: "100%", md: "auto" }}
        borderRadius="1.8rem"
        backgroundColor={props?.backgroundColor}
        boxShadow={`inset 0rem -.150rem ${props?.textColor}`}
        cursor="pointer"
        onClick={() => {
          setOpenModal(!openModal);
        }}
      >
        <Flex justifyContent="space-between">
          <Text>17 Jan 2024</Text>
          <Icon as={BsThreeDotsVertical} />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight="600"
            textAlign={"center"}
            mt={"1rem"}
            fontSize={{ sm: "1.2rem", md: "xl" }}
            lineHeight={{ sm: "5", md: "7" }}
          >
            {props?.category}
          </Text>
          {/* <Text fontSize={{sm:'.8rem',md:'1rem'}}>{props?.subCategory}</Text> */}
        </Flex>
        <Flex flexDirection="column">
          <Text
            mt={".6rem"}
            color={{ sm: "blackAlpha.800", md: "inherit" }}
            fontWeight={{ md: "500" }}
          >
            Progress
          </Text>
          <Box position="relative">
            <Progress
              w="100%"
              height={{ sm: ".5rem", md: ".6rem" }}
              value={props?.progress}
              colorScheme={props?.progressBarColor}
            />
            <Text
              position="absolute"
              transform="translate(10%,-3px)"
              right="0"
              p="0.3rem"
            >
              {props?.progress}%
            </Text>
          </Box>
        </Flex>
        <HSeparator
          backgroundColor="white"
          marginTop={{ sm: "1rem", md: "1.5rem" }}
        />

        <Flex
          my={".3rem"}
          px={".5rem"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Flex
            paddingX={".4rem"}
            borderRadius={"md"}
            gap={".1rem"}
            border={"3px solid"}
            fontWeight={"semibold"}
            borderColor={props?.textColor}
          >
            <Text>C</Text>
            <Text color={props?.textColor}>R</Text>
            <Text>B</Text>
          </Flex>
          {isRating && (
            <Flex gap={".125rem"} opacity={".8"} w={"50%"} justify={"flex-end"}>
              {Array.from({ length: 5 }).map((_, index) => (
                <img
                  key={index}
                  src={Star}
                  width={"17%"}
                  style={{
                    cursor: "pointer",
                    filter: index < rating ? "none" : "grayscale(100%)", // Highlight selected stars
                  }}
                  onClick={() => handleStarClick(index)} // Handle star click
                />
              ))}
            </Flex>
          )}
          {!isRating && (
            <Box bg={"#fff"} borderRadius={"1rem"}>
              <Text
                color={props?.textColor}
                letterSpacing={"normal"}
                paddingY=".3rem"
                paddingX={".6rem"}
                fontSize={{ sm: ".5rem", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                2 Days Left
              </Text>
            </Box>
          )}
          {/* {isRating && (
            <Flex gap={".125rem"} opacity={".8"} w={"50%"} justify={"flex-end"}>
              {Array.from({ length: 5 }).map((_, index) => (
                <img key={index} src={Star} width={"17%"} />
              ))}
            </Flex>
          )}
          {!isRating && (
            <Box bg={"#fff"} borderRadius={"1rem"}>
              <Text
                color={props?.textColor}
                letterSpacing={"normal"}
                paddingY=".3rem"
                paddingX={".6rem"}
                fontSize={{ sm: ".5rem", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                2 Days Left
              </Text>
            </Box>
          )} */}
        </Flex>
      </Box>

      {/* Project Details Modal */}
      <Modal
        size="xl"
        isOpen={openModal}
        width={{ sm: "80%", md: "100%" }}
        onClose={() => {
          setOpenModal(!openModal);
        }}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#EBF2FA">
          {props.category === "Logo Design" ? (
            <ModalBody
              padding={"0px"}
              borderRadius={"20px"}
              overflow={"hidden"}
            >
              <LogoProjectDetails openModal={() => setOpenModal(!openModal)} />
            </ModalBody>
          ) : props.category === "Web Development" ? (
            <ModalBody
              padding={"0px"}
              borderRadius={"20px"}
              overflow={"hidden"}
            >
              <WebProjectDetails openModal={() => setOpenModal(!openModal)} />
            </ModalBody>
          ) : (
            <>
              <ModalHeader>{props.category}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Details</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%">
                    <Avatar size="sm" />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="CustomerName"
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={TbSettingsCog} />
                    <Input ml="0.5rem" variant="flushed" placeholder="SEO" />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={SlGraph} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="Banking & Finance"
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={FaRegCalendarAlt} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="24 Jan 2024 to 24 Feb 2024"
                    />
                  </Flex>
                </Flex>
                <Text>Pricings</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={BsClock} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="2 Weeks"
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={GoBell} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="Immediately"
                    />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
                  <Icon as={TbTag} />
                  <Input variant="flushed" placeholder="Banking & Finance" />
                </Flex>
                <Text>Payment Details</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={MdOutlinePriceChange} />
                    <Input ml="0.5rem" variant="flushed" placeholder="25000" />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={LiaPercentageSolid} />
                    <Input ml="0.5rem" variant="flushed" placeholder="500" />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={VscBellSlash} />
                    <Input ml="0.5rem" variant="flushed" placeholder="2000" />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon colorSchema="#89664C" as={FaSackDollar} />
                    <Input ml="0.5rem" variant="flushed" placeholder="22500" />
                  </Flex>
                </Flex>
                <Text mt="0.8rem" mb="0.8rem">
                  Project Requirement
                </Text>
                <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                <Text mt="0.8rem" mb="0.8rem" fontSize="sm">
                  We are facing a lot of problem in our orgainc growth and we
                  want somenone to improve it. This is our requirement.
                </Text>
                <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                <Text mt="1rem" mb="1rem">
                  Payment Structure
                </Text>
                <Flex justifyContent="space-between">
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#FFF9EA"
                    color="#F3AD05"
                  >
                    25
                  </Box>
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#FFEEF8"
                    color="#D42D91"
                  >
                    25
                  </Box>
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#E5E6F9"
                    color="#407BFF"
                  >
                    25
                  </Box>
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#E1F3EF"
                    color="#06D2A6"
                  >
                    25
                  </Box>
                </Flex>
                <Flex mt="1rem" pm="1rem" flexDirection="column">
                  <Text mb="1rem">Progress Meter</Text>
                  <Box position="relative">
                    <Progress w="100%" value="80" />
                    <Text
                      position="absolute"
                      transform="translate(10%,-3px)"
                      fontWeight="bold"
                      right="0"
                      p="0.3rem"
                    >
                      80%
                    </Text>
                  </Box>
                </Flex>
                <Flex pt="1rem" pb="1rem" alignItems="center">
                  <Text>Update Progress</Text>
                  <Input
                    w="15%"
                    mr="0.5rem"
                    ml="0.5rem"
                    backgroundColor="#FFFFFF"
                    border="1px solid"
                    borderColor="black"
                    borderRadius="1rem"
                  />
                  <Text>%</Text>
                </Flex>
              </ModalBody>

              <Text ml="1.5rem">Payment Status</Text>
              <ModalFooter
                display="flex"
                justifyContent="flex-start"
                color="#FFFFFF"
              >
                <Button backgroundColor="#65C756">Received</Button>
                <Button ml="1rem" backgroundColor="#F28F8F">
                  Pending
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProjectCard;
