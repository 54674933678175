import React from "react";
import coverPage from "../assets/cover-image 1.png";
import coverPageMobile from '../assets/cover-image-mobile.png'
import { Image } from "@chakra-ui/react";

const CoverSection = () => {
    return (
        <div style={{ margin: 'auto', width: 'fit-content', position: 'relative', top: '6px', top: 'calc(-7px)' }}>
            <Image
                
                src={coverPage}
                alt=""
                width={{sm:'300px',md:'700px'}}
            />
        </div>
    );
};

export default CoverSection;
