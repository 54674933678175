import { Box, Button, Flex, Image, SimpleGrid, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import image from "../assets/65ba30d1d05c102577d13d0dd4fcbc2e.png";
import image3 from "../assets/video.png";
import image2 from "../assets/benefits.png";
import image4 from "../assets/424e5b58613715c4771c2c8e70dd8116.png";
import image5 from "../assets/c472f8c544dc4313a0aa5d5a7ed354c1.jpg";
import { FaArrowRight } from "react-icons/fa6";
import { FaPlay } from "react-icons/fa";

const Resource = () => {
  const columns = useBreakpointValue({ base: 1, md: 3 });
  const padding = useBreakpointValue({ base: "0 2rem", md: "0px 10rem 0px 8rem" });
  const textAlign = useBreakpointValue({ base: "left", md: "center" });
  const justifyContent = useBreakpointValue({ base: "flex-start", md: "center" });

  return (
    <div style={{ position: "relative", padding }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "8px", justifyContent }}>
        {/* Blue line for mobile view */}
        {textAlign === "left" && (
          <div
            style={{
              width: "43px",
              height: "4px",
              backgroundColor: "#1C6ED0",
              marginRight: "8px", // Add margin to the right of the line
            }}
          />
        )}

        {/* Resources text */}
        <Text textAlign={textAlign} color="#37474F" fontSize="20px" fontWeight="700">
          Resources
        </Text>
      </div>
      <SimpleGrid
        columns={columns} // 1 column for mobile, 3 for desktop
        spacing={5}
        mt={5}
      >
        {/* Resource cards */}
        {/* Card1 */}
        <Box
          bg="#fff"
          height="fit-content"
          boxShadow="0px 0px 0px 0px #fff"
          // maxW="sm"
          maxW={{base: "500px", md: "sm"}}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          transition="transform 0.3s ease-in-out"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Image style={{ height: "180px", width: "100%" }} src={image} />
          <Flex flexDirection="column" p={2}>
            <Text p={2} fontSize="15px">
              Business Consulting
            </Text>
            <Text p={1} fontSize="15px" fontWeight="600">
              10 Marketing Strategies to Revive a Stagnant User Base
            </Text>
            <Text p={1} fontSize="12px">
              Imagine pouring your heart and soul into a business, only to watch your user base plateau....
            </Text>
            <Flex justifyContent="center" margin="1rem">
              <Button
                style={{
                  height: "2rem",
                  width: "12rem",
                  border: "1px solid #407BFF",
                  alignItems: "center",
                }}
                onClick={() => window.location.href = "https://www.bizzowl.com/resources/blogs/bs/10-marketing-strategies-to-revive-a-stagnant-user-base"}
              >
                <Text fontSize="14px" color="#407BFF">
                  Read More
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Box>
        {/* Card2   */}

        <Flex flexDir={"column"} justifyContent={'space-between'} gridRow="span 2">
          <Box
            height="fit-content"
            boxShadow="0px 0px 0px 0px #fff"
            maxW="sm"
            w="18rem"
            borderWidth="1px"
            borderRadius="lg"
            position={"relative"}
            onClick={() => window.location.href = "https://www.bizzowl.com/resources/blogs/bc/why-small-and-medium-enterprises-need-professional-consulting-services"}
            cursor="pointer"
            transition="transform 0.3s ease-in-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Flex
              flexDirection="column"
              p={2}
              position={"absolute"}
              bottom={0}
              right={0}
            >
              <Text p={1} fontSize=".9rem" fontWeight={"light"} color="#000">
                Business Consulting
              </Text>
              <Text p={1} fontSize=".9rem" fontWeight={"bold"} color="#000">
                Why Small and Medium Enterprises Need Professional Consulting Services
              </Text>
            </Flex>
            <Image style={{ height: "180px", width: "100%" }} src={image2} />
          </Box>
          {/* second last card  */}
          <Box
            bg="#407BFF"
            height="fit-content"
            boxShadow="0px 0px 0px 0px #fff"
            maxW="sm"
            w="18rem"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            transition="transform 0.3s ease-in-out"
            _hover={{ transform: "scale(1.05)" }}
            mt={{base: "1.3rem", md: "0"}}
          >
            <Flex flexDirection="column" p={2}>
              <Text p={2} fontSize="15px" color="#fff">
                Business Consulting
              </Text>
              <Text p={1} fontSize="18px" fontWeight="600" color="#fff">
                Pre-seed to Seed: Why EarlyStage Startups Need Business Consulting (A Founder's Complete Guide)
              </Text>
              <Text p={1} fontSize="15px" color="#fff">
                Remember learning to drive? You could've figured it out alone, but having an experienced...
              </Text>
              <Flex margin="1rem">
                <Button
                  style={{
                    height: "2.5rem",
                    width: "2.5rem",
                    backgroundColor: "#407BFF",
                    border: "1px solid #fff",
                    alignItems: "center",
                    borderRadius: "50%",
                  }}
                  onClick={() => window.location.href = "https://www.bizzowl.com/resources/blogs/bc/pre-seed-to-seed-why-earlystage-startups-need-business-consulting-a-complete-guide"}
                >
                  <Text fontSize="14px" color="#fff">
                    <FaArrowRight />
                  </Text>
                </Button>
              </Flex>
            </Flex>
            <Image style={{ height: "190px", width: "100%" }} src={"https://res.cloudinary.com/dcduojrhf/image/upload/v1731077653/Importance_of_business_COnsulatncy_rd58t2.png"} />
          </Box>
        </Flex>

        {/* Additional resource boxes go here */}

        <Flex
          height="fit-content"
          boxShadow="0px 0px 0px 0px #fff"
          maxW="sm"
          w="18rem"
          borderWidth="1px"
          borderRadius="lg"
          flexDirection={"column"}
          backgroundImage={"linear-gradient(to bottom,#9cc3f0,white)"}
          transition="transform 0.3s ease-in-out"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            backgroundColor={"#407bff"}
            w={"fit-content"}
            m={"1rem"}
            mb={"0px"}
            alignSelf={"end"}
            fontSize={".8rem"}
            p={".1rem 1rem"}
            color={"white"}
            borderRadius={10}
          >
            <Text>Youtube Channel</Text>
          </Box>
          <iframe
            style={{ width: "100%", alignSelf: "center", padding: "1rem", borderRadius: "23px" }}
            src="https://www.youtube.com/embed/lw2X3PxKlAY"
            title="YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <Flex flexDirection="column" p={2}>
            <Text p={1} fontSize="1rem" fontWeight={"500"} color="#000">
              How To Perfectly Pitch Your Seed Stage Startup With Y Combinator's Michael Seibel
            </Text>
          </Flex>
        </Flex>
        <Flex
          height="fit-content"
          boxShadow="0px 0px 0px 0px #fff"
          maxW="sm"
          w="18rem"
          borderWidth="1px"
          borderRadius="lg"
          flexDirection={"column"}
          backgroundImage={"linear-gradient(to top,#fed0e9,white)"}
          transition="transform 0.3s ease-in-out"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Box
            backgroundColor={"#fed0e9"}
            w={"fit-content"}
            m={"1rem"}
            mb={"0px"}
            alignSelf={"end"}
            fontSize={".8rem"}
            p={".1rem 1rem"}
            color={"white"}
            borderRadius={10}
          >
            <Text>Youtube Channel</Text>
          </Box>
          <iframe
            style={{ width: "100%", alignSelf: "center", padding: "1rem", borderRadius: "23px" }}
            src="https://www.youtube.com/embed/m7LvNTbaqSI"
            title="YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

          <Flex flexDirection="column" p={2}>
            <Text p={1} fontSize="1rem" fontWeight={"500"} color="#000">
              Lessons from 1,000+ YC startups: Resilience, tar pit ideas, pivoting, more | Dalton Caldwell (YC)
            </Text>
          </Flex>
        </Flex>

        {/* last card  */}
        <Box
          bg="#407BFF"
          height="fit-content"
          boxShadow="0px 0px 0px 0px #fff"
          maxW="sm"
          w="18rem"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          transition="transform 0.3s ease-in-out"
          _hover={{ transform: "scale(1.05)" }}
        >
          <Image style={{ height: "180px", width: "100%" }} src={"https://res.cloudinary.com/dwjzihwuq/image/upload/v1724100311/photo1_yntb2p.png"} />
          <Flex flexDirection="column" p={2}>
            <Text p={1} fontSize="15px" color="#fff">
              Public Relations
            </Text>
            <Text p={1} fontSize="20px" fontWeight="600" color="#fff">
              Top Benefits of Using an Aggregator for Online Press Release Distribution
            </Text>
            <Text p={1} fontSize="12px" color="#fff">
              Discover how using an aggregator for online press release distribution can streamline ...
            </Text>
            <Flex justifyContent="center">
              <Button
                style={{
                  height: "2rem",
                  width: "12rem",
                  backgroundColor: "#407BFF",
                  border: "1px solid #fff",
                  alignItems: "center",
                }}
                mt="1rem"
                onClick={() => window.location.href = "https://www.bizzowl.com/resources/blogs/pr/top-benefits-of-using-an-aggregator-for-online-press-release-distribution"}
              >
                <Text fontSize="14px" color="#fff">
                  Read More
                </Text>
              </Button>
            </Flex>
          </Flex>
        </Box>
      </SimpleGrid>
    </div>
  );
};

export default Resource;