import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Img,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CardTimeline from "../assets/img/system-uicons_card-timeline.png";
import {
  Avatar,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { GoBell } from "react-icons/go";
import { TbTag } from "react-icons/tb";
import { HSeparator } from "components/separator/Separator";

import noEnquiry from "../assets/img/No_Enquiry.png";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Calendar } from "react-calendar";
import WebEnquiryDetails from "views/admin/servicesDetail/enquirieDetail/WebEnquiryDetails";
import LogoEnquiryDetails from "views/admin/servicesDetail/enquirieDetail/LogoEnquiryDetails";
import { EnquiryRow } from "../data/ProjectData";

const EnquiryTable = ({ columnData, rowData }) => {
  const [unSubscribe, setUnSubscribe] = useState(null);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [enquiryData, setEnquiryData] = useState(rowData);
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [enquiry, setEnquiry] = useState({});
  // Calender

  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState("13 Apr 2024");
  const [endDate, setEndDate] = useState("20 Apr 2024");
  const [numberOfDays, setNumberOfDays] = useState("15 Days");
  const [months, setMonths] = useState([]);

  // useEffect(() => {
  //   getServiceRequests();
  //   return () => {
  //     if (unSubscribe) {
  //       unSubscribe();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log("Enquiry data stored:", enquiryData);
  // }, [enquiryData]);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       const userid = user.uid;
  //       const userProfileRef = collection(db, "userProfiles");
  //       const clientEnquiryData = collection(db, "clientFormData");

  //       const q1 = query(userProfileRef, where("uid", "==", userid));
  //       const querySnapshot = await getDocs(q1);
  //       if (!querySnapshot.empty) {
  //         const UserData = querySnapshot.docs[0].data();
  //         const email = UserData.personal.email;
  //         try {
  //           const q2 = query(
  //             clientEnquiryData,
  //             where("personalDetails.email", "==", email)
  //           );
  //           const enquirySnapshot = await getDocs(q2);
  //           const enquiries = [];
  //           for (let item of enquirySnapshot.docs) {
  //             console.log("Item data:", item.data());
  //             enquiries.push(item.data());
  //           }
  //           setEnquiryData(enquiries);
  //         } catch (error) {
  //           console.log(error.message);
  //         }
  //       }
  //     } else {
  //       console.log("user does not exist in personal section");
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);

  // const getServiceRequests = async () => {
  //   try {
  //     const clientUid = sessionStorage.getItem("uid");
  //     // const userQuery = query(
  //     //   collection(db, "quotatiojns")
  //     // );
  //     const subScribe = onSnapshot(collection(db, "quotations"), (snapshot) => {
  //       const serviceRequests = snapshot.docs.map((doc, index) => {
  //         // if (doc.data().userDetails.uid === clientUid) {
  //         return {
  //           ...doc.data(),
  //           srNo: index + 1,
  //           id: doc.id,
  //         };
  //         // }
  //       });
  //       setEnquiryData(serviceRequests);
  //     });
  //     setUnSubscribe(() => subScribe);
  //   } catch (error) {
  //     console.log(error.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const totalPages = Math.ceil(enquiryData.length / itemsPerPage);
  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayData = enquiryData.slice(startIndex, endIndex);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const columnDataMobile = ["Service", "Date", "Price", "Timeline", "Status"];

  const getColorStatus = (status) => {
    switch (status) {
      case "Open":
        return "#263238";
      case "In Progress":
        return "#54D242";
      case "Pending":
        return "#E2A30E";
      case "Completed":
        return "#407BFF";
      case "Rejected":
        return "#F90D0D";
      default:
        return "black";
    }
  };

  const viewEnquiryDetails = async (item) => {
    setEnquiry(item);
    setOpenActionModal(true);
  };

  const handleDateRange = (dateRange) => {
    if (dateRange.length > 0) {
      setStartDate(
        `${dateRange[0].getDate()} ${
          months[dateRange[0].getMonth() + 1]
        } ${dateRange[0].getFullYear()}`
      );
      setEndDate(
        `${dateRange[1].getDate()} ${
          months[dateRange[1].getMonth() + 1]
        } ${dateRange[1].getFullYear()}`
      );
      const diffRange = Math.ceil(
        (dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24)
      );
      if (diffRange >= 31) {
        const months = Math.floor(diffRange / 30);
        const days = diffRange % 30;
        if (months > 1 && days > 1) {
          setNumberOfDays(`${months} months ${days} days`);
        } else if (months > 1 && days == 1) {
          setNumberOfDays(`${months} months ${days} day`);
        } else if (months === 1 && days > 1) {
          setNumberOfDays(`${months} month ${days} days`);
        } else if (months === 1 && days === 1) {
          setNumberOfDays(`${months} month ${days} day`);
        }
      } else if (diffRange > 1) {
        setNumberOfDays(`${diffRange} days`);
      } else {
        setNumberOfDays(`${diffRange} day`);
      }
    }
    setOpenCalendar(!openCalendar);
  };
  const [activeTab, setActiveTab] = useState("yourEnquiries");
  // Filtering Data Based on Active Tab
  // Filtering Data Based on Active Tab
  // Ensure rowData is always an array
  const safeRowData = Array.isArray(rowData) ? rowData : [];

  // Filtering Data Based on Active Tab
  let filteredData = [];

  if (activeTab === "yourEnquiries") {
    filteredData = safeRowData.filter(
      (item) =>
        item.status !== "Completed" &&
        item.status !== "Rejected" &&
        !isSixMonthsOld(item.date)
    );
  } else if (activeTab === "completedEnquiries") {
    filteredData = safeRowData.filter((item) => item.status === "Completed");
  } else if (activeTab === "rejectedEnquiries") {
    filteredData = safeRowData.filter(
      (item) => item.status === "Rejected" || isSixMonthsOld(item.date)
    );
  }

  // Function to check if an enquiry is older than 6 months
  function isSixMonthsOld(dateString) {
    if (!dateString) return false; // Handle empty date case
    const enquiryDate = new Date(dateString);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    return enquiryDate < sixMonthsAgo;
  }

  return (
    <>
      <Box
        display={{ sm: "none", md: "block" }}
        style={{
          border: "3px solid",
          borderRadius: "0.5rem",
          borderColor: "#D3D3D3",
        }}
      >
        <Flex m="0.5rem 1rem" justifyContent="space-between">
          <Flex fontSize={"1.1rem"} gap="1rem">
            <Text
              fontWeight="600"
              letterSpacing=".1px"
              opacity={activeTab === "yourEnquiries" ? "1" : "0.8"}
              color={activeTab === "yourEnquiries" ? "blue.600" : "#263238"}
              borderBottom={
                activeTab === "yourEnquiries" ? "2px solid #3182CE" : "none"
              }
              cursor="pointer"
              onClick={() => setActiveTab("yourEnquiries")}
            >
              Your Enquiries
            </Text>
            <Text
              fontWeight="600"
              letterSpacing=".1px"
              opacity={activeTab === "completedEnquiries" ? "1" : "0.6"}
              color={
                activeTab === "completedEnquiries" ? "blue.600" : "#263238"
              }
              borderBottom={
                activeTab === "completedEnquiries"
                  ? "2px solid #3182CE"
                  : "none"
              }
              cursor="pointer"
              onClick={() => setActiveTab("completedEnquiries")}
            >
              Completed Enquiries
            </Text>
            <Text
              fontWeight="600"
              letterSpacing=".1px"
              opacity={activeTab === "rejectedEnquiries" ? "1" : "0.8"}
              color={activeTab === "rejectedEnquiries" ? "blue.600" : "#263238"}
              borderBottom={
                activeTab === "rejectedEnquiries" ? "2px solid #3182CE" : "none"
              }
              cursor="pointer"
              onClick={() => setActiveTab("rejectedEnquiries")}
            >
              Rejected Enquiries
            </Text>
          </Flex>

          {/* Calender Btn  */}
          <Flex
            display={{ sm: "none", md: "flex" }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Img
              src={CardTimeline}
              alt="CardTimeline"
              style={{ width: "30px", height: "30px", marginRight: "0.6rem" }}
            />
            <Text mr="0.6rem">Past {numberOfDays}</Text>
            <Flex position="relative">
              <ChevronDownIcon
                mr="0.6rem"
                fontSize="2rem"
                onClick={() => setOpenCalendar(!openCalendar)}
                cursor="pointer"
              />
              {openCalendar && (
                <Box
                  position="absolute"
                  top="33px"
                  left="-116px"
                  style={{ backgroundColor: "white" }}
                >
                  <Calendar
                    selectRange={true}
                    onChange={handleDateRange}
                    view={"month"}
                    tileContent={<Text color="brand.500"></Text>}
                    prevLabel={
                      <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                    }
                    nextLabel={
                      <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                    }
                  />
                </Box>
              )}
            </Flex>
            <Text mr="0.6rem">{startDate}</Text>
            <Text>
              <span style={{ marginRight: "0.6rem", fontSize: "0.8rem" }}>
                To
              </span>
              {endDate}
            </Text>
          </Flex>
        </Flex>
        <TableContainer>
          {filteredData.length === 0 ? (
            <Flex justifyContent="center" marginY={"1rem"} color="#263238">
              <Box textAlign="center">
                <Img
                  src={noEnquiry}
                  height={{ sm: "180px", md: "200px" }}
                  width={{ sm: "250px", md: "300px" }}
                  opacity="0.7"
                />
                <Text
                  fontWeight="semibold"
                  mt="1rem"
                  fontSize={{ sm: "1rem", md: "1.3rem" }}
                  opacity="0.9"
                >
                  No Enquiries Yet
                </Text>
              </Box>
            </Flex>
          ) : (
            <Table
              variant="simple"
              style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
            >
              <Thead mb="1rem">
                <Tr backgroundColor="#D0E3F3">
                  {columnData?.map((item, index) => (
                    <Th
                      padding={{ sm: ".2rem", md: "1rem" }}
                      fontSize={{ sm: ".4rem", md: ".8rem" }}
                      key={index}
                      textAlign="center"
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody textAlign={"center"}>
                {filteredData.map((item, index) => (
                  <Tr
                    key={index}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.1rem 0.1rem 0rem #00000040"
                    textAlign={"center"}
                    cursor={"pointer"}
                    onClick={()=>viewEnquiryDetails(item)}
                  >
                    <Td textAlign={"center"}>
                      <Flex direction="column">
                        <Text fontWeight="bold">
                          {item?.categoryDetails?.subCategory}
                        </Text>
                      </Flex>
                    </Td>
                    <Td textAlign={"center"}>
                      <Flex direction="column">
                        <Text fontWeight="bold">
                          {item?.createdAt.split(" ")[0]}
                        </Text>
                        <Text fontSize="sm">{item?.createdAt.split(" ")[1]}</Text>
                      </Flex>
                    </Td>
                    <Td fontWeight="bold" textAlign={"center"}>
                      ₹{item?.projectDetails?.priceRange[0]}-
                      {item?.projectDetails?.priceRange[1]}
                    </Td>
                    <Td fontWeight="bold" textAlign={"center"}>
                      {item?.projectDetails?.projectTimeline}
                    </Td>
                    <Td fontWeight="bold" textAlign={"center"}>
                      {item.status}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
        <Flex justifyContent="space-between" alignItems="center" m="1rem 2rem">
          <Button
            onClick={handlePrev}
            backgroundColor="#9BC5EF"
            border="1px solid #000000"
            borderRadius="5px"
          >
            Back
          </Button>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={handleNext}
            backgroundColor="#9BC5EF"
            border="1px solid #000000"
            borderRadius="5px"
          >
            Next
          </Button>
        </Flex>
      </Box>

      {/* Enquiries table for mobile  */}

      <Box
        display={{ sm: "block", md: "none" }}
        borderRadius="0.5rem"
        marginTop={"3rem"}
        mb={"1rem"}
      >
        <Flex
          fontSize={"13px"}
          padding={".3rem .2rem"}
          alignItems={"center"}
          fontWeight={"600"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          {/* <Flex gap={".5rem"} letterSpacing={".2px"}>
            <Text
              fontWeight="600"
              letterSpacing={".1px"}
              opacity={".9"}
              color="#263238"
            >
              Your Enquiries
            </Text>
          </Flex> */}
          <Flex gap=".5rem">
            <Text
              fontWeight="600"
              fontSize='12px'
              // letterSpacing=".1px"
              opacity={activeTab === "yourEnquiries" ? "1" : "0.6"}
              color={activeTab === "yourEnquiries" ? "blue.600" : "#263238"}
              borderBottom={
                activeTab === "yourEnquiries" ? "2px solid #3182CE" : "none"
              }
              cursor="pointer"
              onClick={() => setActiveTab("yourEnquiries")}
            >
              Your Enquiries
            </Text>
            <Text
              fontWeight="600"
              fontSize='12px'
              // letterSpacing=".1px"
              opacity={activeTab === "completedEnquiries" ? "1" : "0.6"}
              color={
                activeTab === "completedEnquiries" ? "blue.600" : "#263238"
              }
              borderBottom={
                activeTab === "completedEnquiries"
                  ? "2px solid #3182CE"
                  : "none"
              }
              cursor="pointer"
              onClick={() => setActiveTab("completedEnquiries")}
            >
              Completed Enquiries
            </Text>
            <Text
              fontWeight="600"
              // letterSpacing=".1px"
              fontSize='12px'
              opacity={activeTab === "rejectedEnquiries" ? "1" : "0.6"}
              color={activeTab === "rejectedEnquiries" ? "blue.600" : "#263238"}
              borderBottom={
                activeTab === "rejectedEnquiries" ? "2px solid #3182CE" : "none"
              }
              cursor="pointer"
              onClick={() => setActiveTab("rejectedEnquiries")}
            >
              Rejected Enquiries
            </Text>
          </Flex>

          {/* Calender Btn  */}
          <Flex alignItems="center">
            {/* <Flex
              alignItems={"center"}
              lineHeight={"3"}
              bg={"#D7E9FD"}
              borderRadius={"2xl"}
              paddingRight=".3rem"
              boxShadow="0rem 0.15rem .2rem 0rem #00000040"
            > */}
              {/* <ChevronDownIcon
                color={"#1C6ED0"}
                fontSize={"1.8rem"}
                onClick={() => setOpenCalendar(!openCalendar)}
                cursor="pointer"
              /> */}
              {/* <Img src={CardTimeline} alt="CardTimeline" boxSize={"1.2rem"}  /> */}
              <Image
                src={CardTimeline}
                alt="CardTimeline"
                boxSize={{ sm: "1.4rem", md: "1.8rem" }}
                onClick={() => setOpenCalendar(!openCalendar)}
              />
              {openCalendar && (
                <Box
                  position="absolute"
                  zIndex={5}
                  top="33px"
                  left="21px"
                  style={{ backgroundColor: "white" }}
                  fontSize={{ sm: ".8rem", md: "1rem" }}
                  boxSize={{ sm: "16rem", md: "auto" }}
                >
                  <Calendar
                    selectRange={true}
                    onChange={handleDateRange}
                    view={"month"}
                    tileContent={<Text color="brand.500"></Text>}
                    prevLabel={
                      <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                    }
                    nextLabel={
                      <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                    }
                  />
                </Box>
              )}
              {/* <Text mr={".3rem"} fontSize={".7rem"}>
                {numberOfDays}
              </Text>
              <Img src={CardTimeline} alt="CardTimeline" boxSize={"1.2rem"} /> */}
            </Flex>
          </Flex>
        {/* </Flex> */}
        <TableContainer>
          {filteredData.length === 0 ? (
            <Flex
              justifyContent="center"
              direction="column"
              marginY="1rem"
              color="#263238"
            >
              <Table
                variant="simple"
                color="#455A64"
                fontWeight="600"
                style={{ borderCollapse: "separate", borderSpacing: "0 .8rem" }}
              >
                <Thead textAlign="center">
                  <Tr backgroundColor="#D0E3F3">
                    {columnDataMobile?.map((item, index) => (
                      <Th
                        textAlign="center"
                        padding=".6rem .5rem"
                        fontSize=".7rem"
                        key={index}
                      >
                        {item}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
              </Table>
              <Box textAlign="center" margin="auto">
                <Img
                  src={noEnquiry}
                  fontWeight="bold"
                  height={{ sm: "180px", md: "200px" }}
                  width={{ sm: "250px", md: "300px" }}
                  opacity="0.7"
                />
                <Text
                  fontWeight="semibold"
                  mt="1rem"
                  fontSize={{ sm: "1rem", md: "1.4rem" }}
                  opacity="0.9"
                >
                  No Enquiries Yet
                </Text>
              </Box>
            </Flex>
          ) : (
            <Table
              variant="simple"
              color="#455A64"
              fontWeight="600"
              style={{ borderCollapse: "separate", borderSpacing: "0 .8rem" }}
            >
              <Thead textAlign="center">
                <Tr backgroundColor="#D0E3F3">
                  {columnDataMobile?.map((item, index) => (
                    <Th
                      textAlign="center"
                      padding=".6rem .5rem"
                      fontSize=".7rem"
                      key={index}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredData.map((item, index) => (
                  <Tr
                    key={index}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.1rem 0.1rem 0rem #00000040"
                    cursor={"pointer"}
                    onClick={() => {
                      viewEnquiryDetails(item);
                    }}
                  >
                    <Td
                      fontSize=".7rem"
                      padding=".6rem .8rem"
                      width="2rem"
                      paddingRight="0px"
                    >
                      <Flex direction="column" lineHeight="3">
                        <Text
                          whiteSpace="normal"
                          color="#263238"
                          opacity="0.8"
                          wordWrap="break-word"
                        >
                          {item?.categoryDetails?.subCategory}
                        </Text>
                      </Flex>
                    </Td>
                    <Td fontSize=".7rem" padding=".6rem .8rem">
                      <Flex flexDir="column">
                        <Text lineHeight="3">
                          {item?.serviceDetails?.projectStart}
                        </Text>
                        <Text
                          lineHeight="3"
                          fontWeight="normal"
                          color="#1B2559"
                          fontSize=".5rem"
                        >
                          {item?.time}
                        </Text>
                      </Flex>
                    </Td>
                    <Td fontSize=".7rem" padding=".6rem .8rem">
                      <Text>
                        ₹{item?.projectDetails?.priceRange[0]}-
                        {item?.projectDetails?.priceRange[1]}
                      </Text>
                    </Td>
                    <Td fontSize=".7rem" padding=".6rem .8rem">
                      {item?.projectDetails?.projectTimeline}
                    </Td>
                    <Td fontSize=".7rem" padding=".6rem .8rem">
                      {item.status}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Box>

      <Modal
        size="xl"
        isOpen={openActionModal}
        onClose={() => {
          setOpenActionModal(!openActionModal);
        }}
      >
        <ModalOverlay />
        <ModalContent backgroundColor="#EBF2FA">
          {enquiry?.categoryDetails?.category === "graphic design" ? (
            <ModalBody
              padding={"0px"}
              borderRadius={"20px"}
              overflow={"hidden"}
            >
              <LogoEnquiryDetails
                openActionModal={() => setOpenActionModal(!openActionModal)}
                data={enquiry}
              />
            </ModalBody>
          ) : enquiry?.categoryDetails?.category === "web & app" ? (
            <ModalBody
              padding={"0px"}
              borderRadius={"20px"}
              overflow={"hidden"}
            >
              <WebEnquiryDetails
                openActionModal={() => setOpenActionModal(!openActionModal)}
                data={enquiry}
              />
            </ModalBody>
          ) : (
            <>
              <ModalHeader>Digital Marketing</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>Details</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%">
                    <Avatar size="sm" />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="CustomerName"
                    />
                  </Flex>
                  <Flex w="50%">
                    <Input ml="0.5rem" variant="flushed" placeholder="SEO" />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={SlGraph} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="Banking & Finance"
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={FaRegCalendarAlt} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="24 Jan 2024 to 24 Feb 2024"
                    />
                  </Flex>
                </Flex>
                <Text>Pricings</Text>
                <Flex pt="1rem" pb="1rem">
                  <Flex w="50%" alignItems="center">
                    <Icon as={BsClock} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="2 Weeks"
                    />
                  </Flex>
                  <Flex w="50%" alignItems="center">
                    <Icon as={GoBell} />
                    <Input
                      ml="0.5rem"
                      variant="flushed"
                      placeholder="Immediately"
                    />
                  </Flex>
                </Flex>
                <Flex pt="1rem" pb="1rem" w="50%" alignItems="center">
                  <Icon as={TbTag} />
                  <Input variant="flushed" placeholder="Banking & Finance" />
                </Flex>
                <Text pt="0.8rem" pb="0.8rem">
                  Project Requirement
                </Text>
                <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                <Text mt="0.8rem" mb="0.8rem" fontSize="sm">
                  We are facing a lot of problem in our organic growth and we
                  want somenone to improve it. This is our requirement.
                </Text>
                <HSeparator boxShadow="0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.4)" />
                <Text mt="1rem" mb="1rem">
                  Status
                </Text>
                <Flex justifyContent="space-between">
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#D8F9E6"
                    color="#5DEF92"
                  >
                    New
                  </Box>
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#5DEF9233"
                    color="#5DEF92"
                  >
                    Open
                  </Box>
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#65C756"
                    color="#FFFFFF"
                  >
                    In Progress
                  </Box>
                  <Box
                    w="25%"
                    mr="0.5rem"
                    textAlign="center"
                    backgroundColor="#E0E0E0"
                    color="#455A64BF"
                  >
                    Closed
                  </Box>
                </Flex>
              </ModalBody>
              <Text ml="1.5rem">Take Action</Text>
              <Flex
                ml="1.5rem"
                mt="1rem"
                mb="1rem"
                justifyContent="flex-start"
                color="#FFFFFF"
              >
                <Button backgroundColor="#407BFF">Quote Price</Button>
                <Button ml="1rem" backgroundColor="#F28F8F">
                  Not Interested
                </Button>
              </Flex>
              <FormControl mt="1rem" mb="1rem" w="90%" ml="1.5rem">
                <FormLabel htmlFor="question">
                  Have more Questions to ask before you bid?
                </FormLabel>
                <Input
                  id="question"
                  type="text"
                  placeholder="Type your Question"
                  minHeight="6rem"
                  backgroundColor="#FFFFFF"
                />
              </FormControl>
              <ModalFooter>
                <Button backgroundColor="#407BFF" color="#F5F5F5">
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnquiryTable;
