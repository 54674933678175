import { useMediaQuery } from "@mui/material";

import likeSvg from "./assets/like.svg";

import { Button, Flex } from "@chakra-ui/react";

const ComingSoon = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isTab = useMediaQuery("(max-width:1024px)");

return (
    <>
        <Flex
            justifyContent="center"
            style={{
                background: "linear-gradient(to right, #ebf8ff, #ebf8ff)",
                overflowX: "hidden",
            }}
        >
            <div
                style={{
                    fontFamily: "'Cabin', sans-serif",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px 0",
                    textAlign: "center",
                }}
            >
                <div>
                    <img
                        src={likeSvg}
                        style={{
                            width: isMobile ? "140px" : isTab ? "150px" : "240px",
                            height: isMobile ? "140px" : isTab ? "150px" : "240px",
                        }}
                    />
                </div>
                <div>
                    <p
                        style={{
                            fontSize: isMobile ? "14px" : isTab ? "16px" : "1rem",
                            fontWeight: "500",
                            margin: "0.5rem 0",
                        }}
                    >
                        We are Still
                    </p>
                    <h1
                        style={{
                            fontSize: isMobile ? "32px" : isTab ? "42px" : "3rem",
                            color: "#1C6ED0",
                            fontWeight: "bold",
                        }}
                    >
                        Working on This Service.
                    </h1>
                    <div
                        style={{
                            fontSize: isMobile ? "12px" : isTab ? "18px" : "1rem",
                            padding: isMobile ? "0 3rem" : isTab ? "0 0.5rem" : "0",
                            maxWidth: "600px",
                            margin: "0 auto",
                            textAlign: "center",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            lineHeight: "1.5",
                        }}
                    >
                        <p>
                            We are working on this service, we shall be live with this
                            pretty soon. Meanwhile you can explore other services and
                            features.
                        </p>
                    </div>
                </div>

                <Flex
                    style={{
                        // padding: isMobile ? "0 6rem" : isTab ? "0 4rem" : "0",
                        justifyContent: "space-between",
                    }}
                    w={{sm:'300px',md:'600px'}}
                    margin={{sm: "1.5rem 0", md: "2rem 0"}}
                >
                    <Button colorScheme="blue">Explore More</Button>
                    <Button colorScheme="blue" variant="outline">
                        Talk to Someone
                    </Button>
                </Flex>
            </div>
        </Flex>
    </>
  );
};

export default ComingSoon;
