import {
  Button,
  Flex,
  Grid,
  Image,
  Text
} from "@chakra-ui/react";

import businessCard from "../assets/Img/business-card.png";
import closeBtn from "../assets/Img/closeBtn.png";
import internet from "../assets/Img/internet.png";

import { useEffect, useState } from "react";
import add from "../assets/logoDetails/add.png";
import adidas from "../assets/logoDetails/adidas.png";
import branding from "../assets/logoDetails/branding.png";
import cadbery from "../assets/logoDetails/cadbery.png";
import colorWheel from "../assets/logoDetails/color-wheel.png";
import flowChart from "../assets/logoDetails/flow-chart.png";
import industrial from "../assets/logoDetails/industrial.png";
import inventory from "../assets/logoDetails/inventory.png";
import oilPrice from "../assets/logoDetails/oil-price.png";
import preferences from "../assets/logoDetails/preferences.png";
import prototype from "../assets/logoDetails/prototype.png";
import revision from "../assets/logoDetails/revision.png";
import schedule from "../assets/logoDetails/schedule.png";
import spacing from "../assets/logoDetails/spacing.png";
import timetable from "../assets/logoDetails/timetable.png";
import voice from "../assets/logoDetails/voice.png";

function LogoEnquiryDetails({ openActionModal, data }) {
  console.log(data);
  const { categoryDetails, personalDetails, serviceDetails } = {
    categoryDetails: {},
    serviceDetails: {},
    projectDetails: {},
  };

  const [callOptions, setCallOptions] = useState(false);
  useEffect(() => {
    const handleClickOutside = () => {
      setCallOptions(false);
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <Flex
      flexDirection={"column"}
      rowGap={{ sm: ".5rem", md: ".7rem" }}
      borderRadius={"20px"}
      padding={"20px"}
      color={"#455A64"}
      fontFamily={"Cabin,sans-serif"}
      fontSize={{ sm: "smaller", md: "1rem" }}
      boxShadow={"4px 4px 4px 5px rgba(0, 0, 0, 0.25)"}
    >
      <Flex justifyContent={"space-between"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ sm: "1.150rem", md: "1.8rem" }}
          color="#1B2559"
          paddingX={"8px"}
        >
          {categoryDetails?.category}
        </Text>
        <Button
          bg={"transparent"}
          size={{ sm: "xs", md: "sm" }}
          onClick={openActionModal}
        >
          <Image
            src={closeBtn}
            width={{ sm: "15px", md: "20px" }}
            height={{ sm: "15px", md: "20px" }}
          />
        </Button>
      </Flex>
      <Text
        fontSize={{ sm: "1rem", md: "1.5rem" }}
        color={"#1B2559"}
        paddingX={"8px"}
      >
        Details
      </Text>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
        // fontSize={{sm:'smaller',md:"large"}}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Startup Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={businessCard}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Bizowl</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={internet}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>www.bizowl.com </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Type of Logo
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={spacing}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Wordmark, Modern</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Style of Logo
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={prototype}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Minimilist, Modern </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Concept
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={inventory}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>3</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Revisions
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={revision}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>3</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            TAT
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={timetable}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>4 days</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={schedule}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>24 jan 2025, 5:05PM </Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Text
          fontSize={{ sm: "small", md: "medium" }}
          color={"rgba(27, 37, 89, 0.8)"}
        >
          Pricing
        </Text>
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={oilPrice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text>2-5K</Text>
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={add}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Additional Branding Assets
          </Text>
        </Flex>
        <Text w={"50%"}>
          Letterhead, Branding Guidelines, Font, Visiting Card
        </Text>
      </Flex>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Business Model
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={flowChart}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Wordmark, Modern</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Industry Type
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={industrial}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Minimilist, Modern </Text>
          </Flex>
        </Flex>
      </Grid>
      <Flex
        flexDirection={"column"}
        gap={"3px"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Text
          fontSize={{ sm: "small", md: "medium" }}
          color={"rgba(27, 37, 89, 0.8)"}
        >
          Color Scheme
        </Text>
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={colorWheel}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text>3</Text>
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column"}
        gap={".8rem"}
        padding={".4rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={preferences}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Logo Preference
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={{ sm: "1rem", md: "2rem" }}>
          <Image
            src={cadbery}
            width={{ sm: "20px", md: "35px" }}
            height={{ sm: "20px", md: "35px" }}
          />
          <Image
            src={adidas}
            width={{ sm: "20px", md: "35px" }}
            height={{ sm: "20px", md: "35px" }}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={voice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Project Requirements
          </Text>
        </Flex>

        <Text>
          We are facing a lot of problem in our organic growth and we want
          someone to improve it. This is our requirement.{" "}
        </Text>
      </Flex>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={branding}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Branding Proposition
          </Text>
        </Flex>
        <Text>
          We are facing a lot of problem in our organic growth and we want
          someone to improve it. This is our requirement.{" "}
        </Text>
      </Flex>
      <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
        <Text color={"rgba(27, 37, 89, 0.8)"}>Quotes</Text>
        <Button
          bg={"#407bff"}
          color={"#fff"}
          variant="solid"
          w={{ sm: "6rem", md: "7rem" }}
          size={"sm"}
        >
          Quotes Link
        </Button>
      </Flex>

      <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
        <Text color={"rgba(27, 37, 89, 0.8)"}>Take Action</Text>

        <Flex gap={{ sm: "1rem", md: "4rem" }} alignItems={"center"}>
          <Button
            bg={"#65c756"}
            color={"#fff"}
            w={{ sm: "6rem", md: "7rem" }}
            size={"sm"}
          >
            Edit Enquiry
          </Button>
          <Button
            bg={"#f28f8f"}
            color={"#fff"}
            w={{ sm: "7rem", md: "8rem" }}
            size={"sm"}
          >
            Close Enquiry
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LogoEnquiryDetails;
