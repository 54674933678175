"use client"

import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, useToast } from "@chakra-ui/react"
import { db } from "config/firebase"
import { collection, doc, getDocs, updateDoc, where, query } from "firebase/firestore"

import * as yup from "yup"

import { onAuthStateChanged } from "firebase/auth"
import { BusinessStages, Employees, Industries, Services } from "../data/ServiceData"
import { useEffect, useState } from "react"
import { useMediaQuery } from "@mui/material"
import { auth } from "config/firebase"

const BusinessInfo = ({ onUpdate }) => {
  const [partnerId, setPartnerId] = useState("")
  const toast = useToast()
  const isMobile = useMediaQuery("(max-width:640px)")
  const [UserId, setUserId] = useState()
  const [errors, setErrors] = useState({
    businessInfo: {
      businessName: "",
      gstNumber: "",
      businessStage: "",
      Employees: "",
      businessIndustry: "",
      reqService: "",
    },
  })

  const [formData, setFormData] = useState({
    businessInfo: {
      businessName: "",
      gstNumber: "",
      businessStage: "",
      Employees: "",
      businessIndustry: "",
      reqService: "",
    },
  })

  const dataSchema = yup.object().shape({
    businessInfo: yup.object().shape({
      businessName: yup.string().required("This field is required!"),
      gstNumber: yup.string().required("This field is required!"),
      businessStage: yup.string().required("This field is required!"),
      Employees: yup.string().required("This field is required!"),
      businessIndustry: yup.string().required("This field is required!"),
      reqService: yup.string().required("This field is required!"),
    }),
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid
        setUserId(userid)
      } else {
        console.log("user does not exist")
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    getData()
  }, [UserId])

  const addDetails = async () => {
    try {
      const profileDocRef = collection(db, "userProfiles")
      const q = query(profileDocRef, where("uid", "==", UserId))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const docId = querySnapshot.docs[0].id
        const docRef = doc(db, "userProfiles", docId)
        await updateDoc(docRef, formData)
        toast({
          title: "Data Updated successfully!",
          description: "The user data was succesfully updated!",
          status: "success",
          duration: "2500",
          isClosable: true,
        })

        // Call the onUpdate callback to recalculate profile completion
        if (onUpdate) onUpdate()
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const getData = async () => {
    try {
      if (!UserId) {
        console.log("User Id does not exist!")
      } else {
        const profileDocRef = collection(db, "userProfiles")
        const q = query(profileDocRef, where("uid", "==", UserId))
        const querySnapshot = await getDocs(q)
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data()
          const businessInfoData = data.businessInfo
          setFormData((prevData) => ({
            ...prevData,
            businessInfo: {
              ...businessInfoData,
            },
          }))
        }
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      businessInfo: { ...prevData.businessInfo, [name]: value },
    }))

    dataSchema
      .validateAt(`businessInfo.${name}`, { ...formData, businessInfo: { ...formData.businessInfo, [name]: value } })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, businessInfo: { ...prevErrors.businessInfo, [name]: "" } }))
      })
      .catch((err) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          businessInfo: { ...prevErrors.businessInfo, [name]: err.message },
        }))
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isFormValid = await dataSchema.isValid(formData)
    if (isFormValid) {
      addDetails()
    } else {
      toast({
        title: "Data Submission Failed!",
        description: "There are errors in form!",
        status: "error",
        duration: "2500",
        isClosable: true,
      })
    }
  }

  // Update profile completion on field change
  useEffect(() => {
    // Debounce to avoid too many updates
    const timer = setTimeout(() => {
      if (onUpdate) onUpdate()
    }, 500)

    return () => clearTimeout(timer)
  }, [formData])

  return (
    <Box w="100%" borderWidth="1px" borderRadius="lg" overflow="hidden" padding="2rem">
      <form onSubmit={handleSubmit}>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.businessInfo.businessName}>
            <FormLabel htmlFor="businessName">Business Name</FormLabel>
            <Input
              id="businessName"
              name="businessName"
              type="text"
              required
              onChange={handleChange}
              value={formData.businessInfo.businessName}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.businessInfo.businessName}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.businessInfo.gstNumber}>
            <FormLabel htmlFor="gstNumber">GST Number</FormLabel>
            <Input
              id="gstNumber"
              name="gstNumber"
              type="text"
              onChange={handleChange}
              value={formData.businessInfo.gstNumber}
              style={{ backgroundColor: "white" }}
            />
            <FormErrorMessage>{errors.businessInfo.gstNumber}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.businessInfo.businessStage}>
            <FormLabel htmlFor="experience">Business Stage</FormLabel>
            <Select
              mb="4"
              id="businessStage"
              name="businessStage"
              required
              placeholder="Select option"
              onChange={handleChange}
              value={formData.businessInfo.businessStage}
              backgroundColor="white"
            >
              {BusinessStages?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.businessInfo.businessStage}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.businessInfo.Employees}>
            <FormLabel htmlFor="numberOfEmployees">No. of Employees</FormLabel>
            <Select
              mb="4"
              id="Employees"
              name="Employees"
              placeholder="Select option"
              onChange={handleChange}
              value={formData.businessInfo.Employees}
              backgroundColor="white"
            >
              {Employees?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.businessInfo.Employees}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mb="5" justifyContent="space-between" flexDirection={isMobile && "column"}>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.businessInfo.businessIndustry}>
            <FormLabel htmlFor="experience">Business Industry</FormLabel>
            <Select
              mb="4"
              id="businessIndustry"
              name="businessIndustry"
              placeholder="Select option"
              onChange={handleChange}
              value={formData.businessInfo.businessIndustry}
              backgroundColor="white"
            >
              {Industries?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.businessInfo.businessIndustry}</FormErrorMessage>
          </FormControl>
          <FormControl w={isMobile ? "80%" : "45%"} isInvalid={!!errors.businessInfo.reqService}>
            <FormLabel htmlFor="reqService">Required Business Services</FormLabel>
            <Select
              mb="4"
              id="reqService"
              name="reqService"
              placeholder="Select option"
              onChange={handleChange}
              value={formData.businessInfo.reqService}
              backgroundColor="white"
            >
              {Services?.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.businessInfo.reqService}</FormErrorMessage>
          </FormControl>
        </Flex>
        <Button mt="5" colorScheme="blue" type="submit">
          Save
        </Button>
      </form>
    </Box>
  )
}

export default BusinessInfo

