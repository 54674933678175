import supportData from "../data";
const SupportSection = () => {
  return (
    <div style={{ fontFamily: "Cabin" }}>
      <h1
        style={{
          fontSize: "25px",
          fontWeight: "bold",
          marginTop: "27px",
          marginLeft: "12px",
          marginRight: "12px",
          color: "#1C6ED0",
        }}
      >
        <span style={{ color: "#1C6ED0" }}>Welcome! </span>
        How can we help?
      </h1>
      <p
        style={{
          fontSize: "12px",
          color: "gray",
          maxWidth: "695px",
          margin: "15px auto 0",
          fontFamily: "Inter",
        }}
      >
        Hi there stuck somewhere? don’t worry we’re here to help. Check out the
        options below to get help. Don’t worry whatever it is together we will
        sort out.
      </p>
      <div
        style={{
          display: "flex",
          gap: "68px",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "63px",
          marginBottom: "63px",
        }}
      >
        {supportData.map((data) => {
          return (
            <div
              style={{
                backgroundColor: "#D7E9FD",
                display: "flex",
                flexDirection: "column",
                borderRadius: "24px",
                width: "208px",
                height: "272px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                height={"200px"}
                width={"150px"}
                style={{ height: "96px" }}
                src={data.img}
                alt={data.head}
              />
              <div>
                <h3
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginBottom: "8px",
                  }}
                >
                  {data.head}
                </h3>
                <p style={{ fontSize: "12px", color: "gray" }}>{data.data}</p>
              </div>
              <p
                style={{
                  color: "#1C6ED0",
                  fontSize: "14px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                {data.button}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SupportSection;
