import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  Input,
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React, { useEffect, useState, useRef } from "react";
import ProfilePic from "../assets/Ellipse 658.png";
import { ChatData } from "../data/InboxData";
import { HSeparator } from "components/separator/Separator";
import { MdOutlineVideocam } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { BiAddToQueue } from "react-icons/bi";
import { HiMiniPaperClip } from "react-icons/hi2";
import { BsEmojiSmile } from "react-icons/bs";
import chatImg from "../assets/b322c3cceed34f76af0713c9a57805b5.jpg";
import noMessage from '../../assets/No_Messages.png';
import { auth } from "config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";
import ChatInput from "./ChatInput";
import { SearchIcon } from "@chakra-ui/icons";


const Chat = () => {
  const [chatData, setChatData] = useState([]);
  const [UserId, setUserId] = useState();
  const [profileSection, setProfileSection] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageData, setMessageData] = useState([]);
  const [openChat, setOpenChat] = useState(false);
  const chatContainer = useRef(null);

  const handleProfileSection = () => {
    setProfileSection(true);
  }

  useEffect(() => {
    console.log("chatData:", chatData);
  }, [chatData])



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log(user.uid);
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe;
  }, []);

  const getChats = async () => {
    try {
      const chatCollectionRef = collection(db, "Chats");
      const chatQuery = query(chatCollectionRef, where("clientId", "==", UserId));

      const querySnapshot = await getDocs(chatQuery);
      if (!querySnapshot.empty) {
        const chatDocs = querySnapshot.docs.map((item, index) => ({ ...item.data(), docId: item.id }));
        setChatData(chatDocs);
      }
    } catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    if (UserId) {
      getChats();
    }
  }, [UserId]);

  const getMessages = async (docId) => {
    try {
      const messageCollectionRef = collection(db, `Chats/${docId}/Messages`);
      const unsubscribe = onSnapshot(messageCollectionRef, (Snapshot) => {
        if (!Snapshot.empty) {
          const messageDocs = Snapshot.docs.map((doc) => doc.data());
          console.log("Message docs", messageDocs);
          setMessageData(messageDocs.sort((a, b) => b?.exactTime - a?.exactTime));
        }
      })

      return () => unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChatSelection = (docId, chatId) => {
    setOpenChat(true);
    const selectedChatDoc = chatData.filter((item) => item.chatId === chatId);
    console.log("Selected chat:", selectedChatDoc[0]?.clientName);
    setSelectedChat(selectedChatDoc[0]);
    getMessages(docId);
  }

  useEffect(() => {
    console.log(selectedChat?.clientName)
  }, [selectedChat])


  // if(chatData.length === 0){
  //     return (
  //       <Flex
  //         justifyContent="center"
  //         alignItems={"center"}
  //         flexDirection={"column"}
  //         marginY={{sm:'.2rem',md:"1rem"}}
  //         color="#263238"
  //       >
  //         <Box textAlign="center">
  //           <Image
  //             src={noMessage}
  //             height={{ sm: "250px", md: "350px" }}
  //             width={{ sm: "250px", md: "350px" }}
  //             opacity="0.9"
  //           />
  //           <Text fontWeight="semibold" fontSize={{ sm: "1rem", md: "1.3rem" }}>
  //             No Message Here
  //           </Text>
  //         </Box>
  //         <Flex gap={10} mt={5}>
  //           <Button colorScheme={"blue"} borderRadius={'.6rem'}>Back</Button>
  //           <Button colorScheme={"blue"} variant="outline" borderRadius={'.6rem'}>
  //             Dashboard
  //           </Button>
  //         </Flex>
  //       </Flex>
  //     );
  // }
  return (
    <Flex
      p={{ sm: '0px', md: "1rem 0.3rem" }}
      border="1px solid #00000033"
      borderRadius=".8rem"
      minHeight="30rem"
      boxShadow="0 0 0.4rem 0.25rem rgba(64, 123, 255, 0.25)"

      justifyContent="space-between"
    >
      <Box width={{ base: "90%", md: "40%" }} >
        <Flex
          align="center"
          background="#F2F2F2"
          borderRadius="50px"
          padding="0.5rem 1rem"
          ml={{ base: "1.1rem", md: ".8rem" }}  
          mr={{ base: "auto", md: "0" }}
          mt={{ base: "1rem", md: ".3rem" }}  
          boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
          width={{ base: "100%", md: "60%" }}  
          justifyContent="center"
        >
          <SearchIcon color="#1C6ED0" mr="0.5rem" />
          <Input
            placeholder="Search"
            variant="unstyled"
            fontSize="1rem"
            color="black"
            _placeholder={{ color: "gray.500" }}
          />
        </Flex>
        {chatData.map((item) => {
          return (
            <Box key={item?.chatId} onClick={() => handleChatSelection(item?.docId, item?.chatId)}>
              <Flex p="1rem" cursor="pointer">
                <Avatar src={item?.partnerProfileImage ? item?.partnerProfileImage : ProfilePic} alt="Profile Pic" />
                <Flex ml="1rem" flexDirection="column">
                  <Text fontWeight="bold">{item?.partnerName}</Text>
                  <Text fontSize="sm">Message</Text>
                </Flex>
              </Flex>
              <HSeparator />
            </Box>
          );
        })}
      </Box>

      {(openChat && selectedChat) ? (<Box ml="1rem" width={profileSection ? "38%" : "60%"}>
        <Flex justifyContent="space-between" alignItems="center" onClick={handleProfileSection}>
          <Flex>
            <Avatar src={selectedChat?.partnerProfileImage ? selectedChat?.partnerProfileImage : ProfilePic} alt="Profile Pic" />
            <Box ml="0.5rem">
              <Text fontSize="lg" fontWeight="bold">
                {selectedChat?.partnerName}
              </Text>
              <Text>Online</Text>
            </Box>
          </Flex>
          <Flex ml="3rem">
            <Icon as={MdOutlineVideocam} color="blue" />
            <Icon as={IoCallOutline} ml="1rem" color="blue" />
          </Flex>
        </Flex>
        <Flex height="350px" overflowY="scroll" flexDirection="column-reverse">
          {messageData?.map((item, index) => {
            if (item?.messageBy === "partner") {
              return (
                <Flex pt="3rem" flexDirection="column">
                  <Flex
                    color="white"
                    p="1rem"
                    width="70%"
                    borderRadius="0.625rem 0.625rem 1.25rem 0rem"
                    bgColor="#455A64B2"
                  >
                    <Text>{item?.message}</Text>
                  </Flex>
                  <Text fontSize="0.8rem">9:12PM</Text>
                </Flex>
              )
            } else {
              return (
                <Flex pt="1rem" flexDirection="column" alignItems="flex-end">
                  <Flex
                    color="white"
                    p="1rem"
                    width="70%"
                    borderRadius="0.625rem 0.625rem 0rem 1.25rem"
                    bgColor="#407BFFE5"
                  >
                    <Text>{item?.message}</Text>
                  </Flex>
                  <Text fontSize="0.8rem" pr="1rem">
                    9:12PM
                    <span style={{ fontWeight: "bold", paddingLeft: "0.6rem" }}>
                      You
                    </span>
                  </Text>
                </Flex>
              )
            }
          })}
        </Flex>
        <ChatInput selectedChat={selectedChat} />
      </Box>) : ""}


      {/* Profile Section */}
      {profileSection ? (<Box ml="1rem" width="30%">
        <Flex justifyContent="flex-end">
          <Icon as={RxCross2} color="blue" onClick={() => { setProfileSection(false) }} />
        </Flex>

        <Flex
          mt="2rem"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar src={selectedChat?.partnerProfileImage ? selectedChat?.partnerProfileImage : ProfilePic} alt="Profile Pic" />
          <Text fontSize="lg" fontWeight="bold">
            {selectedChat?.partnerName}
          </Text>
          <Text color="#2EB123">Online</Text>
        </Flex>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Text fontWeight="bold">Files</Text>
                  <Text fontSize="xs">2 Files</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text>Sampleswork.docx</Text>
              <Text>Sampleswork.pdf</Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Text fontWeight="bold">Images</Text>
                  <Text fontSize="xs">4 Photos</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex flexDirection="row" gap={2}>
                <span>
                  <img style={{ borderRadius: "10px" }} src={chatImg} />
                </span>
                <span>
                  <img style={{ borderRadius: "10px" }} src={chatImg} />
                </span>
              </Flex>
              <Flex flexDirection="row" gap={2} mt={2}>
                <span>
                  <img style={{ borderRadius: "10px" }} src={chatImg} />
                </span>
                <span>
                  <img style={{ borderRadius: "10px" }} src={chatImg} />
                </span>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>) : ""}
    </Flex>
  );
};

export default Chat;
