import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { partnerDB } from "config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const EventClient = ({ data }) => {
  const [partner, setPartner] = useState([]);
  const [partnername, setPartnerName] = useState(null);
  
  const partnerId = data?.partnername;
  const time = data?.meetingTime;
  const serviceName = data?.meetingType;
  const meetingId = data?.meetingId;

  console.log("Meeting ID received:", meetingId);

  useEffect(() => {
    if (!partnerId) return;

    const fetchPartner = async () => {
      try {
        const meetingsRef = collection(partnerDB, "userProfiles");
        const q = query(meetingsRef, where("uid", "==", partnerId));
        const querySnapshot = await getDocs(q);

        const partnerdata = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPartner(partnerdata);
      } catch (error) {
        console.error("Error fetching partner:", error);
      }
    };

    fetchPartner();
  }, [partnerId]);

  useEffect(() => {
    const businessName = partner?.[0]?.businessInfo?.businessName;
    setPartnerName(businessName);
  }, [partner]);





  // Function to open meeting in a new window


  const openMeeting = () => {
    if (!meetingId) {
        console.error("Meeting ID is missing!");
        return;
    }

    const meetingUrl = `/meeting/${meetingId}`; // Adjust the path if needed
    window.open(meetingUrl, "_blank");
};


  // const openMeeting = () => {
  //   if (!meetingId) {
  //     console.error("Meeting ID is missing!");
  //     return;
  //   }
    
  //   const meetingUrl = `/meeting/${meetingId}`; // Adjust the path if needed
  //   window.open(meetingUrl, "_blank", "width=800,height=600");
  // };

  return (
    <Flex
      p="1rem"
      boxShadow="-3px 0px 1px 0px #407BFF"
      borderRadius="20px"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      onClick={openMeeting} // Open in new tab
    >
      {/* Partner Name Box */}
      <Flex
        p="0.25rem"
        m="1"
        borderRadius="15px"
        border="3.5px solid #A1D4C9"
        pl="1rem"
        pr="1rem"
      >
        <Text fontSize="1.75rem" fontWeight="bold" color="#455A64" letterSpacing="0.25rem">
          <Text as="span" color="#455A64">
            {partnername?.charAt(0)}
          </Text>
          <Text as="span" color="#A1D4C9">
            {partnername?.charAt(1)}
          </Text>
          <Text as="span" color="#455A64">
            {partnername?.charAt(2)}
          </Text>
        </Text>
      </Flex>

      {/* Service Name */}
      <Flex ml="1rem" flexDirection="column" flex="1">
        <Text fontSize="1.25rem" color="#1B2559" ml="1rem">
          {serviceName}
        </Text>
      </Flex>

      {/* Time */}
      <Flex alignItems="center">
        <Text fontSize="1rem" fontWeight="medium" color="#1B2559" textAlign="right">
          {time}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EventClient;
