import img from "../assets/img/3671d04626930bdcb9fda99cb0f33bc9.jpg";
export const Cards = [
  {
    project: "Ongoing Projects",
    number: 0,
    bgColor: "#E5E6F9",
  },
  {
    project: "Completed Projects",
    number: 0,
    bgColor: "#FFF9E9",
  },
  {
    project: "Approved Enquiries",
    number: 0,
    bgColor: "#E1F2EF",
  },
];

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const ColumnData = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
export const RowData = [
  {
    name: "Project 1",
    status: "Approved",
    date: "18 Apr 2022",
    progress: 75.5,
  },
  {
    name: "Project 2",
    status: "Disable",
    date: "18 Apr 2022",
    progress: 25.5,
  },
  {
    name: "Project 3",
    status: "Error",
    date: "20 May 2021",
    progress: 90,
  },
  {
    name: "Project 4",
    status: "Approved",
    date: "12 Jul 2021",
    progress: 50.5,
  },
  {
    name: "Project 5",
    status: "Approved",
    date: "24 Jan 2024",
    progress: 60,
  },
];
export const OngoingProjects = [
  {
    category: "SEO",
    subCategory: "Digital Marketing",
    progress: 90,
    progressBarColor: "blue",
    backgroundColor: "#D7E9FD",
    textColor: "#3965FF",
  },
  {
    category: "Digital Marketing",
    subCategory: "Design",
    progress: 50,
    progressBarColor: "pink",
    backgroundColor: "#FFEEF8",
    textColor: "#D53F8C",
  },
  {
    category: "Web Development",
    subCategory: "Web Development",
    progress: 60,
    progressBarColor: "purple",
    backgroundColor: "#E5E6F9",
    textColor: "#805AD5",
  },
  {
    category: "Logo Design",
    subCategory: "Digital Marketing",
    progress: 60,
    progressBarColor: "teal",
    backgroundColor: "#E1F3EF",
    textColor: "#319795",
  },
  {
    category: "SEO",
    subCategory: "Digital Marketing",
    progress: 45,
    progressBarColor: "yellow",
    backgroundColor: "#FFF9EA",
    textColor: "#D69E2E",
  },
  {
    category: "Web Development",
    subCategory: "Design",
    progress: 55,
    progressBarColor: "blue",
    backgroundColor: "#C6DBF3",
    textColor: "#3965FF",
  },
  {
    category: "SEO",
    subCategory: "Digital Marketing",
    progress: 70,
    backgroundColor: "#C6DBF3",
    textColor: "#3965FF",
  },
  {
    category: "SEO",
    subCategory: "Digital Marketing",
    progress: 20,
    backgroundColor: "#C6DBF3",
    textColor: "#3965FF",
  },
  {
    category: "Logo Design",
    subCategory: "Advertising",
    progress: 70,
    backgroundColor: "#C6DBF3",
    textColor: "#3965FF",
  },
];
// export const EnquiryColumn = [
//   "Details",
//   "Date",
//   "Budget",
//   "Timeline",
//   "Status",
//   "Link",
// ];
export const EnquiryColumn = ["Service","Date","Budget","Timeline","Status"];
export const EnquiryRow = [
  {
    categoryDetails: {
      category: "Website Development"
    },
  },
  {
    category: "SEO",
    subCategory: "Digital Marketing",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "5-7K",
    industry: "Banking",
    timeline: "2 Week",
    status: "Open",
  },
  {
    category: "Web Development",
    subCategory: "Website Design",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "5-7K",
    industry: "IT",
    timeline: "10 Week",
    status: "In Progress",
  },
  {
    category: "Logo Design",
    subCategory: "Web Application",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "50-70K",
    industry: "E-Commerce",
    timeline: "6 months",
    status: "Pending",
  },
  {
    category: "Marketing",
    subCategory: "Social Media Marketing",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "15-27K",
    industry: "E-commerce",
    timeline: "5 Week",
    status: "Completed",
  },
  {
    category: "Marketing",
    subCategory: "Social Media Marketing",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "15-27K",
    industry: "E-commerce",
    timeline: "5 Week",
    status: "Rejected",
  },
  {
    category: "Marketing",
    subCategory: "Social Media Marketing",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "15-27K",
    industry: "E-commerce",
    timeline: "5 Week",
    status: "Rejected",
  },
  {
    category: "Marketing",
    subCategory: "Social Media Marketing",
    date: "24 Jan 2024",
    time: "8:05 AM",
    budget: "15-27K",
    industry: "E-commerce",
    timeline: "5 Week",
    status: "Rejected",
  },
];
export const PaymentHistoryColumn = [
  "Project Name",
  "Date",
  "Invoice",
  "Amount",
  "GST (18%)",
  "Final ₹",
  "Payment",
];
export const PaymentHistoryRow = [
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Paid",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Paid",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Paid",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Pending",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Pending",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Paid",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Paid",
  },
  {
    projectName: "Project 1",
    image: img,
    client: "Service Head",
    date: "24 Jan 2024",
    time: "8:30AM",
    invoice: "BIZ3008001",
    amount: "₹25000",
    gst: "₹500",
    final: "₹2000",
    payment: "Pending",
  },
];
