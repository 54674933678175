import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  Link
} from "@chakra-ui/react";

import businessCard from "../assets/Img/business-card.png";
import closeBtn from "../assets/Img/closeBtn.png";
import internet from "../assets/Img/internet.png";


import oilPrice from "../assets/logoDetails/oil-price.png";
import preferences from "../assets/logoDetails/preferences.png";
import schedule from "../assets/logoDetails/schedule.png";
import timetable from "../assets/logoDetails/timetable.png";
import voice from "../assets/logoDetails/voice.png";
import domain from "../assets/webService/domain.png";
import feature from "../assets/webService/feature.png";
import pages from "../assets/webService/pages.png";
import purpose from "../assets/webService/purpose.png";
import startDate from "../assets/webService/start-date.png";
import techPreference from "../assets/webService/tech-preference.png";

function WebEnquiryDetails({ openActionModal, data }) {
  const {
    categoryDetails,
    personalDetails,
    serviceDetails,
    projectDetails,
    createdAt,
    enquiryID
  } = data;

  return (
    <Flex
      flexDirection={"column"}
      rowGap={{ sm: ".5rem", md: ".7rem" }}
      borderRadius={"20px"}
      padding={"20px"}
      letterSpacing={"wide"}
      color={"#455A64"}
      fontFamily={"Cabin,sans-serif"}
      fontSize={{ sm: "smaller", md: "md" }}
      boxShadow={"4px 4px 4px 5px rgba(0, 0, 0, 0.25)"}
    >
      <Flex justifyContent={"space-between"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ sm: "1.150rem", md: "1.8rem" }}
          color="#1B2559"
          paddingX={"8px"}
        >
          {categoryDetails?.category}
        </Text>
        <Button
          bg={"transparent"}
          size={{ sm: "xs", md: "sm" }}
          onClick={openActionModal}
        >
          <Image
            src={closeBtn}
            width={{ sm: "15px", md: "20px" }}
            height={{ sm: "15px", md: "20px" }}
          />
        </Button>
      </Flex>
      <Text
        fontSize={{ sm: "1rem", md: "1rem" }}
        color={"#1B2559"}
        paddingX={"8px"}
      >
        Details
      </Text>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
        // fontSize={{sm:'smaller',md:"large"}}
      >
        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Startup Name
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={businessCard}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>Bizowl</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: "medium" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={internet}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>www.bizowl.com </Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Purpose of Website
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={purpose}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.purposeOfWebsite} </Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            No of Pages
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={pages}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.numberOfPages} </Text>
          </Flex>
        </Flex>
      </Grid>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Tech Preference
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={techPreference}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.techPreference?.join(", ")}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Design Preference
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={preferences}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.designPreference}</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(1,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={schedule}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{createdAt}</Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={".325rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={feature}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Feature Required
          </Text>
        </Flex>
        <Grid
          gridTemplateColumns={{ sm: "repeat(2,1fr)", md: "1fr 150px 1fr" }}
          rowGap={"1rem"}
          columnGap={{ sm: ".8rem", md: "1rem" }}
        >
          <Flex flexDir={"column"}>
            {serviceDetails?.featuresRequired?.map((feature, i) => (
              <Text key={i}>{feature}</Text>
            ))}
          </Flex>
          {/* <Flex flexDirection="column" fontSize={{ sm: ".6rem", md: ".7rem" }}>
            <Box>
              <Text
                marginBottom={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                Design & User Experience{" "}
              </Text>
              <ul>
                <li>Responsive Design </li>
                <li>Custom UI/UX Design </li>
                <li>Dark Mode Support </li>
                <li>Custom Animations </li>
                <li>Accessibility Compliance</li>
              </ul>
            </Box>
            <Box>
              <Text
                marginY={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                Performance & Security{" "}
              </Text>
              <ul>
                <li>Fast Loading Speed </li>
                <li>Performance Optimization</li>
                <li>Security Features (SSL, Firewall, etc.) </li>
                <li>Backup & Restore </li>
                <li>Database Integration</li>
              </ul>
            </Box>
          </Flex>
          <Box fontSize={{ sm: ".6rem", md: ".7rem" }}>
            <Text
              marginBottom={{ sm: ".8rem", md: "1rem" }}
              fontSize={{ sm: "", md: ".9rem" }}
              fontWeight={"semibold"}
            >
              Functionality & Features
            </Text>
            <ul width="80%">
              <li>Static Website Development</li>{" "}
              <li>Dynamic Website Development</li>{" "}
              <li>CMS Integration (WordPress, Webflow, etc.)</li>{" "}
              <li>E-commerce Integration (Shopify, WooCommerce, etc.)</li>{" "}
              <li>API Integration</li> <li>Payment Gateway Integration</li>
              <li>User Authentication & Login System</li>{" "}
              <li>Admin Panel / Dashboard</li>
            </ul>
          </Box>
          <Flex flexDirection="column" fontSize={{ sm: ".6rem", md: ".7rem" }}>
            <Box>
              <Text
                marginBottom={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                Creative & Content Support
              </Text>
              <ul>
                <li>Custom Graphics & Illustrations</li>{" "}
                <li>Stock Images & Icons</li>{" "}
                <li>Copywriting for Website Pages</li>
              </ul>
            </Box>
            <Box>
              <Text
                marginY={{ sm: ".8rem", md: "1rem" }}
                fontSize={{ sm: "", md: ".9rem" }}
                fontWeight={"semibold"}
              >
                SEO & Marketing
              </Text>
              <ul>
                <li>SEO Optimization (On-Page SEO, Schema, etc.)</li>{" "}
                <li>Blog Setup</li> <li>Social Media Integration</li>{" "}
                <li>Analytics & Tracking Setup</li>{" "}
                <li>Custom Landing Pages</li>
              </ul>
            </Box>
          </Flex> */}
        </Grid>
      </Flex>

      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Domain
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={domain}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.domainHosting}</Text>
          </Flex>
        </Flex>

        <Flex
          flexDirection={"column"}
          gap={".325rem"}
          padding={".5rem"}
          borderBottom={"1px solid #98a6af"}
          boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
        >
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Budget
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={oilPrice}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{projectDetails?.priceRange?.join("-")}</Text>
          </Flex>
        </Flex>
      </Grid>
      <Grid
        templateColumns={"repeat(2,1fr)"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Timeline
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={timetable}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{projectDetails?.projectTimeline}</Text>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Start Date
          </Text>
          <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
            <Image
              src={startDate}
              width={{ sm: "15px", md: "25px" }}
              height={{ sm: "15px", md: "25px" }}
            />
            <Text>{serviceDetails?.projectStart}</Text>
          </Flex>
        </Flex>
      </Grid>

      <Flex
        flexDirection={"column"}
        gap={".5rem"}
        padding={".5rem"}
        borderBottom={"1px solid #98a6af"}
        boxShadow={"0px 1px 0px rgba(0, 0, 0, 0.25)"}
      >
        <Flex alignItems={"center"} gap={{ sm: ".5rem", md: ".7rem" }}>
          <Image
            src={voice}
            width={{ sm: "15px", md: "25px" }}
            height={{ sm: "15px", md: "25px" }}
          />
          <Text
            fontSize={{ sm: "small", md: ".9rem" }}
            color={"rgba(27, 37, 89, 0.8)"}
          >
            Project Requirements
          </Text>
        </Flex>

        <Text lineHeight={"5"}>{projectDetails?.projectDetails}</Text>
      </Flex>

      <Flex
        flexDir={"column"}
        fontSize={{ sm: "small", md: ".9rem" }}
        gap={{ sm: ".2rem", md: ".7rem" }}
        paddingY={"1rem"}
      >
        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Status</Text>
          <Grid
            gridTemplateColumns={"repeat(4,1fr)"}
            columnGap={{ sm: ".8rem", md: "1.3rem" }}
            marginTop={".4rem"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#D8F9E6"}
              color={"#65c756"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              New
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#D8F9E6"}
              color={"#65c756"}
            >
              Open
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
            >
              In Progress
            </Flex>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              Completed
            </Flex>
          </Grid>
        </Box>

        <Box color={"rgba(27, 37, 89, 0.8)"} padding={".5rem"}>
          <Text>Customer Decision Priority</Text>
          <Grid
            gridTemplateRows={"repeat(2,1fr)"}
            gridTemplateColumns={"repeat(3,1fr)"}
            columnGap={{ sm: ".8rem", md: "1.3rem" }}
            rowGap={{ sm: ".5rem", md: "1rem" }}
            marginTop={".4rem"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              Cost
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
            >
              Experience
            </Flex>

            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              Quality Work
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
              borderTopStartRadius={".9rem"}
              borderBottomStartRadius={".9rem"}
            >
              Timeline
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#E0E0E0"}
            >
              Ratings
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={{ sm: "2rem", md: "2.5rem" }}
              bg={"#65c756"}
              color={"#fff"}
              borderTopEndRadius={".9rem"}
              borderBottomEndRadius={".9rem"}
            >
              <Text textAlign={"center"}>Payment Structure</Text>
            </Flex>
          </Grid>
        </Box>

        <Flex flexDirection={"column"} gap={"3px"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Quotes</Text>
          <Link href={`https://www.bizzowl.com/services/web-devlopment-distribution/quote-details/${enquiryID}`}>
          <Button
            bg={"#407bff"}
            color={"#fff"}
            variant="solid"
            w={{ sm: "6rem", md: "7rem" }}
            size={"sm"}
          >
            Quotes Link
          </Button>
          </Link>
        </Flex>

        <Flex flexDirection={"column"} gap={".325rem"} padding={".5rem"}>
          <Text color={"rgba(27, 37, 89, 0.8)"}>Take Action</Text>

          <Flex gap={{ sm: "1rem", md: "4rem" }} alignItems={"center"}>
            <Button
              bg={"#65c756"}
              color={"#fff"}
              w={{ sm: "6rem", md: "7rem" }}
              size={"sm"}
            >
              Edit Enquiry
            </Button>
            <Button
              bg={"#f28f8f"}
              color={"#fff"}
              w={{ sm: "7rem", md: "8rem" }}
              size={"sm"}
            >
              Close Enquiry
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default WebEnquiryDetails;
