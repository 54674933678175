import { Avatar, Box, Button, Flex, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import { ServiceCards } from "../data/DashboardData";
import { MdKeyboardArrowRight } from "react-icons/md";

const ServiceCard = () => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    setCardData(ServiceCards);
  }, []);

  return (
    <Flex justifyContent="center" w="100%">
      <Flex

        pt={{ base: ".4", lg: ".8rem" }}
        px={{ base: "0", lg: "2rem" }}
        backgroundColor="#FFFFFF"
        w={{ base: "100%", lg: "1200px" }} 
        justifyContent="space-between"
        alignItems="center"
        h="100%"
        borderRadius="10px"
        flexDirection="row"
      >
        {/* Left Side: Heading Section */}
        <Flex flex="1" flexDirection="column" p="1rem">
          <Text fontWeight="bold" fontSize={{ base: "22px", lg: "30px" }} color="#6E9BFE">
            Popular
          </Text>
          <Text fontWeight="bold" fontSize={{ base: "22px", lg: "30px" }}>Service</Text>
          <Text color="#767c9b" fontSize={{ base: "12px", lg: "15px" }}>
            Explore the best services, <br />
            most useful to users.
          </Text>
        </Flex>

        {/* Right Side: Cards Section */}
        <Box w="100%" overflowX={{ base: "auto", lg: "visible" }}>
          <Flex
            alignItems="center"
            gap={{ base: "20px", lg: "35px" }}
            p={{ base: "1.7rem", lg: "2.8rem" }}
            flexDirection="row"
            flexWrap={{ base: "nowrap", lg: "wrap" }}
            justifyContent={{ base: "flex-start", lg: "center" }}
            w="max-content"
          >
            {cardData.map((item, index) => (
              <Card
                key={index}
                onClick={() => (window.location.href = item.link)}
                w={{ base: "110px", lg: "160px" }} 
                h={{ base: "150px", lg: "200px" }}
                p="1rem"
                boxShadow="2px 4px 14px 0px #6E9BFE"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                position="relative"
                cursor="pointer"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.05)" }}
              >
                <Flex
                  backgroundColor={item?.backgroundColor}
                  justifyContent="center"
                  alignItems="center"
                  position="absolute"
                  top="-15px"
                  left="50%"
                  transform="translateX(-50%)"
                  height={{ base: "40px", lg: "50px" }}
                  width={{ base: "40px", lg: "50px" }}
                  borderRadius="50%"
                  transition="transform 0.3s ease-in-out"
                  _hover={{ transform: "rotate(10deg) translateX(-50%)" }}
                >
                  <img
                    style={{ height: "18px", width: "18px" }}
                    src={item?.img}
                    alt="Service Icon"
                  />
                </Flex>

                <Text
                  color={item?.color}
                  fontWeight="500"
                  px="5px"
                  opacity=".9"
                  fontSize={{ base: "0.8rem", lg: "1rem" }}
                  textAlign="center"
                  mt="1rem"
                >
                  {item?.name}
                </Text>
                <Text color="#767c9b" fontSize={{ base: "10px", lg: "12px" }} textAlign="center" mt="0.5rem">
                  {item?.message}
                </Text>
              </Card>
            ))}

            {/* "All Services" Card */}
            <Card
              w={{ base: "120px", lg: "160px" }}
              h={{ base: "160px", lg: "200px" }}
              backgroundColor="#DAEAFD"
              boxShadow="2px 4px 14px 0px #6E9BFE"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p="1rem"
              cursor="pointer"
              transition="transform 0.3s ease-in-out"
              _hover={{ transform: "scale(1.05)" }}
              onClick={() => (window.location.href = "https://www.bizzowl.com/services")}
            >
              <Text mb=".4rem" color="blue.700" fontWeight="bold" fontSize={{ base: "1rem", lg: "1.25rem" }}>
                All Services
              </Text>
              <Button
                mt="1rem"
                backgroundColor="#fff"
                borderRadius="50%"
                width={{ base: "40px", lg: "50px" }}
                height={{ base: "40px", lg: "50px" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MdKeyboardArrowRight size="24px" color="#407BFF" />
              </Button>
            </Card>
          </Flex>
        </Box>

      </Flex>
    </Flex>
  );
};

export default ServiceCard;
