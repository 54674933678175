import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdOutlineHomeRepairService,
  MdHome,
  // MdLock,
  // MdWork,
  // MdPerson,
  MdOutlineShoppingCart,
} from "react-icons/md";

import { GoProject } from "react-icons/go";
import { TiMessageTyping } from "react-icons/ti";
import { TbUserScan } from "react-icons/tb";
import { MdOutlineContactSupport } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/clintProfile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import { RiVoiceRecognitionLine } from "react-icons/ri";

// Auth Imports

import SignInCentered from "views/auth/signIn";
import Project from "views/admin/project";
import Dashboard from "views/admin/dashboards";
import Recommendation from "views/admin/recommendation";
import Message from "views/admin/message";
import SignIn from "views/auth/signIn";
import Login from "components/Auth/Login";
import Logout from "components/Auth/utils/login/Logout";
//client icons for mobile

import dashboardIcon from "./assets/img/dashboards/dashboard.png";
import projectIcon from "./assets/img/dashboards/projects.png";
import msgIcon from "./assets/img/dashboards/messages.png";
import profileIcon from "./assets/img/dashboards/profile.png";
import recommendation from "./assets/img/dashboards/recomemndation.png";
import services from "./assets/img/dashboards/services.png";
import support from "./assets/img/dashboards/support.png";
import logoutIcon from "./assets/img/dashboards/logout.png";
import ComingSoon from "components/coming soon";
import SupportPageBody from "components/support/components/SupportPageBody";
import WebProjectDetails from "views/admin/servicesDetail/projectDetail/WebProjectDetails";
import LogoProjectDetails from "views/admin/servicesDetail/projectDetail/LogoProjectDetails";
import WebEnquiryDetails from "views/admin/servicesDetail/enquirieDetail/WebEnquiryDetails";

const routes = [
  //------------------------//
  //dummy route for testing
  // {
  //   name: "Dashboard",
  //   layout: "/client",
  //   path: "/details",
  //   // icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   // mobIcon: dashboardIcon,
  //   component: WebEnquiryDetails,
  //   // component: LogoProjectDetails,
  // },
  //------------------------//
  {
    name: "Dashboard",
    layout: "/client",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    mobIcon: dashboardIcon,
    component: Dashboard,
    isExternal: false,
  },
  {
    name: "Projects",
    layout: "/client",
    path: "/project",
    icon: <Icon as={GoProject} width="20px" height="20px" />,
    mobIcon: projectIcon,
    component: Project,
    isExternal: false,
  },
  {
    name: "Services",
    layout: "/client",
    path: "/services",
    mobIcon: services,
    icon: <Icon as={MdOutlineHomeRepairService} width="20px" height="20px" />,
    component: NFTMarketplace,
    secondary: true,
    isExternal: true,
    externalUrl: "https://www.bizzowl.com/services",
  },
  {
    name: "Messages",
    layout: "/client",
    path: "/messages",
    mobIcon: msgIcon,
    icon: <Icon as={TiMessageTyping} width="20px" height="20px" />,
    component: Message,
    secondary: true,
    isExternal: false,
  },
  {
    name: "Profile",
    layout: "/client",
    path: "/profile",
    mobIcon: profileIcon,
    icon: <Icon as={TbUserScan} width="20px" height="20px" />,
    component: Profile,
    secondary: true,
    isExternal: false,
  },
  {
    name: "FounderAI",
    layout: "/client",
    path: "/founderai",
    mobIcon: recommendation,
    icon: <Icon as={RiVoiceRecognitionLine} width="20px" height="20px" />,
    component: ComingSoon,
    secondary: true,
    isExternal: false,
  },
  {
    name: "Support",
    layout: "/client",
    path: "/support",
    mobIcon: support,
    icon: <Icon as={MdOutlineContactSupport} width="20px" height="20px" />,
    component: SupportPageBody,
    secondary: true,
    isExternal: false,
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "/login",
    mobIcon: logoutIcon,
    icon: (
      <Icon
        as={FiLogOut}
        width="20px"
        height="20px"
        // color='white'
      />
    ),
    component: Logout,
    secondary: true,
    isExternal: false,
  },

  // {
  //   name: "Bookings and Reservations",
  //   layout: "/client",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },
  //  {
  //     name: "RTL Admin",
  //     layout: "/rtl",
  //     path: "/rtl-default",
  //     icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //     component: RTL,
  //   },
];

export default routes;
