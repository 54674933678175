// assets
import CardTimeline from "./assets/img/system-uicons_card-timeline.png";

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
// import { columnsDataComplex } from "../dataTables/variables/columnsData";
import ComplexTable from "./components/ComplexTable";
import tableDataComplex from "../../admin/default/variables/tableDataComplex.json";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Cards } from "./data/ProjectData";
import CustomCard from "./components/CustomCard";
import { HSeparator } from "components/separator/Separator";
import AvatarImg from "views/admin/project/assets/img/avatar1.png";
import ProjectCard from "./components/ProjectCard";
import ProjectCard2 from "./components/ProjectCard2";
import { OngoingProjects } from "./data/ProjectData";
import { CiCirclePlus } from "react-icons/ci";
import {
  BsThreeDotsVertical,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";

import {
  ColumnData,
  RowData,
  EnquiryColumn,
  EnquiryRow,
  PaymentHistoryRow,
  PaymentHistoryColumn,
} from "./data/ProjectData";


import EnquiryTable from "./components/EnquiryTable";
import PaymentHistoryTable from "./components/OrderHistoryTable";
import { Calendar } from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import PaginationMobile from "./components/PaginationMobile";
import noProjects from "./assets/img/No_Project.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { onAuthStateChanged } from "firebase/auth";
import { db,auth } from "config/firebase";
import { collection,query,where,getDocs} from "firebase/firestore";

const Project = () => {
  const [cards, setCards] = useState([]);
  const [ongoingProjects, setOngoingProjects] = useState(OngoingProjects);
  const [columnData, setColumnData] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [enquiryColumnData, setEnquiryColumnData] = useState([]);
  const [enquiryRowData, setEnquiryRowData] = useState([]);
  const [paymentHistoryColumnData, setPaymentHistoryColumnData] = useState([]);
  const [paymentHistoryRowData, setPaymentHistoryRowData] = useState([]);
  // const itemsPerPage=6;
  const [currentPage, setCurrentPage] = useState(1);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState("13 Apr 2024");
  const [endDate, setEndDate] = useState("20 Apr 2024");
  const [numberOfDays, setNumberOfDays] = useState("15 Days");
  const [months, setMonths] = useState([]);
  const [UserId,setUserId]=useState();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const itemsPerPage = isMobile ? 3 : 6;
  const totalPages = Math.ceil(ongoingProjects.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [isRating, setIsRating] = useState(false);
  
  //dummy data
  // const displayData = ongoingProjects.slice(startIndex, endIndex);
  const displayData = [];


  const getData=async()=>{
    try {
      const collectionRef=collection(db,"clientFormData");
      const docSnapshot = await getDocs(collectionRef);
      const enquiryArray=[];
      if (!docSnapshot.empty) {
        const docIds = docSnapshot.docs.map((item) => item.id);
        for(const docId of docIds){
          const enquiryCollectionRef = collection(
            db,
            `clientFormData/${docId}/enquiries`
          );
          const enquiryQuery=query(enquiryCollectionRef,where("userId","==",UserId));
          const enquirySnapshot = await getDocs(enquiryQuery);
          if (!enquirySnapshot.empty) {
            const newData = enquirySnapshot.docs.map((item) => item.data());
            enquiryArray.push(...newData);
            console.log("enquiryArray:",enquiryArray);
            setRowData((prev)=>{
              let prevEnquiryData=prev;
              let nextEnquiryData=enquiryArray;
              return [...new Set([...prevEnquiryData,...nextEnquiryData])]
            });
          }else{
            break;
          }
          
        }
        
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  useEffect(()=>{
    const unsubscribe=onAuthStateChanged(auth,(user)=>{
      setUserId(user.uid);
    })

    return ()=>unsubscribe();
  },[]);

  useEffect(()=>{
    getData();
  },[UserId]);



  // useEffect(() => {
  //   setCards(Cards);
  //   setOngoingProjects(OngoingProjects);
  //   setColumnData(ColumnData);
  //   setRowData(RowData);
  //   setEnquiryColumnData(EnquiryColumn);
  //   setEnquiryRowData(EnquiryRow);
  //   setPaymentHistoryColumnData(PaymentHistoryColumn);
  //   setPaymentHistoryRowData(PaymentHistoryRow);
  // });

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleDateRange = (dateRange) => {
    if (dateRange.length > 0) {
      setStartDate(
        `${dateRange[0].getDate()} ${
          months[dateRange[0].getMonth() + 1]
        } ${dateRange[0].getFullYear()}`
      );
      setEndDate(
        `${dateRange[1].getDate()} ${
          months[dateRange[1].getMonth() + 1]
        } ${dateRange[1].getFullYear()}`
      );
      const diffRange = Math.ceil(
        (dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24)
      );
      if (diffRange >= 31) {
        const months = Math.floor(diffRange / 30);
        const days = diffRange % 30;
        if (months > 1 && days > 1) {
          setNumberOfDays(`${months} months ${days} days`);
        } else if (months > 1 && days == 1) {
          setNumberOfDays(`${months} months ${days} day`);
        } else if (months === 1 && days > 1) {
          setNumberOfDays(`${months} month ${days} days`);
        } else if (months === 1 && days === 1) {
          setNumberOfDays(`${months} month ${days} day`);
        }
      } else if (diffRange > 1) {
        setNumberOfDays(`${diffRange} days`);
      } else {
        setNumberOfDays(`${diffRange} day`);
      }
    }
    setOpenCalendar(!openCalendar);
  };

  return (
    <>
      <Text fontWeight="bold">Overview</Text>
      <Flex mt="1rem" flexWrap="wrap" justifyContent="space-between">
        {cards.map((item, index) => {
          return <CustomCard key={index} card={item} />;
        })}
      </Flex>

      <Flex marginY="1.5rem" position="relative">
        <Tabs width={"100%"}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <TabList display={"flex"} alignItems={"center"}>
              <Tab
                fontWeight={"semibold"}
                paddingX={{ sm: ".4rem", md: "1rem" }}
                paddingY={{ sm: ".1rem", md: ".5rem" }}
                fontSize={{ sm: "1rem", md: "1rem" }}
              >
                Ongoing Projects
              </Tab>
              <Tab
                fontWeight={"semibold"}
                paddingX={{ sm: ".4rem", md: "1rem" }}
                paddingY={{ sm: ".1rem", md: ".5rem" }}
                fontSize={{ sm: "1rem", md: "1rem" }}
              >
                Completed Projects
              </Tab>
            </TabList>

            {/* Calendar Button */}
            <Flex
              position={{ md: "relative" }}
              justifyContent="space-between"
              alignItems="center"
              me={{ md: "1rem" }}
            >
              <Box
                onClick={() => {
                  console.log("called");
                  setOpenCalendar(!openCalendar);
                }}
              >
                <Image
                  src={CardTimeline}
                  alt="CardTimeline"
                  boxSize={{ sm: "1.4rem", md: "1.8rem" }}
                />
              </Box>

              {openCalendar && (
                <Box
                  position="absolute"
                  zIndex={5}
                  top="33px"
                  left="21px"
                  style={{ backgroundColor: "white" }}
                  fontSize={{ sm: ".8rem", md: "1rem" }}
                  boxSize={{ sm: "16rem", md: "auto" }}
                >
                  <Calendar
                    selectRange={true}
                    onChange={handleDateRange}
                    view={"month"}
                    tileContent={<Text color="brand.500"></Text>}
                    prevLabel={
                      <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                    }
                    nextLabel={
                      <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                    }
                  />
                </Box>
              )}

              <Box display={{ sm: "none", md: "flex" }}>
                <Flex alignItems={"center"}>
                  <Text marginX="0.6rem">Past {numberOfDays}</Text>
                  <ChevronDownIcon
                    mr="0.6rem"
                    fontSize="2rem"
                    onClick={() => setOpenCalendar(!openCalendar)}
                    cursor="pointer"
                  />
                </Flex>
                <Text mr="0.6rem">{startDate}</Text>
                <Text>
                  <span style={{ marginRight: "0.6rem", fontSize: "0.8rem" }}>
                    To
                  </span>
                  {endDate}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <TabPanels>
            {/* Project Cards  */}

            <TabPanel padding="0px">
              <Grid
                columnGap={{ sm: 3, md: 5 }}
                rowGap={{ sm: 8, md: 5 }}
                marginY={"1.5rem"}
                pb={".5rem"}
                templateColumns={{ sm: "repeat(2,1fr)", md: "repeat(4,1fr)" }}
                flexWrap="wrap"
              >
                {displayData.length === 0 ? (
                  <GridItem marginY="2rem" color="#263238" colSpan={6}>
                    <Flex
                      direction="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Image
                        src={noProjects}
                        height={{ sm: "180px", md: "200px" }}
                        width={{ sm: "250px", md: "300px" }}
                        opacity="0.7"
                      />
                      <Text
                        fontWeight="600"
                        mt="1rem"
                        fontSize={{ sm: "1rem", md: "1.3rem" }}
                      >
                        No projects yet
                      </Text>
                    </Flex>
                  </GridItem>
                ) : (
                  displayData.map((item, index) => (
                    <ProjectCard isRating={false} key={index} props={item} />
                  ))
                )}
              </Grid>

              {/* Pagination */}
              {displayData.length !== 0 && (
                <PaginationMobile
                  currentPage={currentPage}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                />
              )}
              <Flex
                display={{ sm: "none", md: "flex" }}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Text>
                  Page {currentPage} of {totalPages}
                </Text>
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Flex>
            </TabPanel>
            <TabPanel padding="0px">
              <Grid
                columnGap={{ sm: 3, md: 5 }}
                rowGap={{ sm: 8, md: 5 }}
                marginY={"1.5rem"}
                pb={".5rem"}
                templateColumns={{ sm: "repeat(2,1fr)", md: "repeat(4,1fr)" }}
                flexWrap="wrap"
              >
                {displayData.length === 0 ? (
                  <GridItem marginY="2rem" colSpan={4}>
                    <Flex
                      direction="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Image
                        src={noProjects}
                        height={{ sm: "180px", md: "200px" }}
                        width={{ sm: "250px", md: "300px" }}
                        opacity="0.7"
                      />
                      <Text
                        fontWeight="semibold"
                        mt="1rem"
                        opacity={".9"}
                        fontSize={{ sm: "1rem", md: "1.3rem" }}
                      >
                        No projects yet
                      </Text>
                    </Flex>
                  </GridItem>
                ) : (
                  displayData.map((item, index) => (
                    <ProjectCard isRating={true} key={index} props={item} />
                  ))
                )}
              </Grid>
              {displayData.length !== 0 && (
                <PaginationMobile
                  currentPage={currentPage}
                  handleNext={handleNext}
                  handlePrev={handlePrev}
                />
              )}
              <Flex
                display={{ sm: "none", md: "flex" }}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Text>
                  Page {currentPage} of {totalPages}
                </Text>
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      {/* dummy data  */}
      <EnquiryTable columnData={EnquiryColumn} rowData={rowData} />

      <Box mt="3rem">
        <PaymentHistoryTable
          columnData={paymentHistoryColumnData}
          rowData={paymentHistoryRowData}
        />
      </Box>
    </>
  );
};
export default Project;
